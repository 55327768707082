.financeiro {
    padding: 4% 12%;
}

.financeiro-content {
    width: 75%;
    margin: auto;
}

.financeiro-content h4{
    font-size: 18px !important;
    font-weight: 600;
    color: #171717;
}

.financeiro-content .informacoes {
    border: 1px solid RGB(54, 71, 92, 0.25);
    border-radius: 4px;
    margin: 20px 0;
    padding: 4%;
    display: flex;
    flex-direction: column;
}

.financeiro-content .informacoes .paragraph{
    color: #303030;
    font-size: 18px;
    line-height: 27px;
    font-weight: 300;
    margin-bottom: 3%;
}
.financeiro-content .informacoes .credito {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin: 2% 0;
    width: 95%;
}

.financeiro-content .informacoes .credito label {
    color: #171717;
    font-size: 18px;
    font-weight: 600;
}

.financeiro-content .informacoes .credito input {
    border-radius: 3px;
    height: 50px;
    background-color: #F0F0F0;
    padding: 3%;
    border: 0px;
    font-size: 20px;
    font-weight: 300;
}
.financeiro-content .informacoes .credito p {
    text-align: right;
    font-size: 16px;
    font-weight: 300;
    color: #303030;
    margin-top: 1%;
}

@media (max-width: 768px) {
    .financeiro-content {
        width: 100%;
        padding: 0 0;
    }
    .financeiro-content .informacoes .credito p {
        font-size: 14px;
    }
}