.terms-page .video{
   width: 90vw !important;
   height: 52.5vw !important; 
   margin: auto !important;
   max-width: 1200px !important;
   max-height: 700px !important;
}

.terms-page h2{
   margin-top: 20px;
   margin-bottom: 20px;
}

.terms-page h4{
   margin-top: 20px;
   margin-bottom: 20px;
}

.terms-page .MsoNormal{
   margin-bottom: 10px
}