.checkout-item-wrapper {

}

.checkout-item-wrapper img {
    max-height: 100px;
    max-width: 100px;
}

.checkout-item-wrapper .item-info {
    margin-left: 30px;
}

.checkout-item-wrapper .item-info .name {
    font-size: 15px;
    line-height: 17px;
    font-weight: 700;
    color: #303030;
}

.checkout-item-wrapper .price {
    font-size: 15px;
    font-weight: 700;
    color: #000000;
}

.checkout-item-wrapper .qtt-holder .amount{
    font-size: 15px;
    font-weight: 300;
}

.checkout-item-wrapper a {
    font-size: 14px;
    font-weight: 500;
    color: #68809E;
    text-decoration: none;
    cursor: pointer;
}

.checkout-item-wrapper .info-img{
    width: 20px;
    height: 20px;
    margin-left: 6px;
    margin-top: -2px;
}

.checkout-item-wrapper .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.checkout-item-wrapper .taxa-info-box{
    position: absolute;
    left: 38%;
    z-index: 1000;
    width: 240px;
    padding: 26px 20px 26px 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    margin-top: 30px;
    }


.checkout-item-wrapper .taxa-info-box .price{
    margin-right: 10px;
    color: black;
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    .checkout-item-wrapper img {
        max-height: 90px;
        max-width: 90px;
    }
}

@media only screen and (max-width: 425px) {
    .checkout-item-wrapper img {
        max-height: 50px;
        max-width: 50px;
    }
    .checkout-item-wrapper .item-info .name {
        font-size: 14px;
        line-height: 16px;
    }
    .checkout-item-wrapper .price {
        font-size: 14px;
    }
}