.suaconta-container {

    padding: 0% 8%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px 0;

    
}

@media (max-width: 768px) {

    .suaconta-container {
        flex-direction: column;
    }
}
