.sel-obj-master .select-object{
    padding: 1px;
    border-radius: 4px;
    border: 1px solid #A3A3A3;
    font-size: 18px;
}


.sel-obj-master .select-object .margin-right{
    margin-right: 10px;
}

.sel-obj-master .select-object .margin-left{
    margin-left: 10px;
}

.sel-obj-master .anchor{
    position: relative;
}

.sel-obj-master .anchor .options-holder{
    top: 105%;
    background: white;
    z-index: 1000;
    max-height: 134px;
    overflow-y: auto;
}

.sel-obj-master .select-box *{
    border: 0 !important;
}

.sel-obj-master .select-object .extra-padding{
    padding: 15px;
}