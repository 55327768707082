.account-page-wrapper {}

.checkbox-group {
  display: flex;
  align-items: center;
}

.input-select{
  border:none;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-input {
  margin-right: 8px; /* Adjust spacing between checkbox and label as needed */
}

.checkbox-label {
  font-size: 14px; /* Adjust font size as needed */
}

.breadcrumbs h3 {
    font-weight: 300;
    font-size: 24px;
    margin-right: 1%;

}

.breadcrumbs h4 {
    font-weight: 300;
    font-size: 22px;
    margin-right: 1%;

}

.breadcrumbs p {
    font-weight: 700;
    font-size: 22px;
    margin-left: 1%;
}

.account-page-wrapper .breadcrumbs {
    color: #414141;
    font-size: 16px;
    font-weight: 300;
}

.account-page-wrapper .main-section {
    width: 100% !important;
    margin-right: 20px;
}

.account-page-wrapper .main-section .first-section {
    display: flex;
    flex-flow: row nowrap;
}

.account-page-wrapper .main-section .id-section {
    width: 15%;
    min-width: 100px;
    margin-right: 30px;
}

.account-page-wrapper .main-section .name-section {
    width: 52%;
    min-width: 300px;
    margin-right: 30px;
}

.suggestion-section .paragraph{
  color: #303030;
  font-size: 18px !important;
  line-height: 27px;
  font-weight: 300 !important;
}

.account-page-wrapper .main-section .cnpj-section {
    width: 26%;
    min-width: 150px;
}

.account-page-wrapper .main-section .address-section {
    width: 100%;
}

.account-page-wrapper .main-section .loader-wrapper {
    position: relative;
    width: 90%;
    margin: auto;
    height: 90px;
}

.account-page-wrapper .main-section p {
    font-size: 18px;
    color: #414141;
    font-weight: 600;
}

.account-page-wrapper .user-wrapper {
    padding: 25px 0px;
}

.account-page-wrapper .user-wrapper input {
    width: 100%;
    outline: none;
    padding: 12px;
    font-size: 16px;
    line-height: 16px !important;
    color: #414141;
    border: 1px solid RGB(54, 71, 92, 0.25);
    background-color: #FFFFFF;
    border-radius: 7px;
    margin-top: 6px;
    margin-bottom: 20px;
}

.account-page-wrapper .user-wrapper input:disabled {
    color: #414141;
    border: 0;
    background-color: #F0F0F0;
}

.account-page-wrapper .user-wrapper form:focus-within {
    outline: none;
    border: 2px solid #9A9A9A;
}


.account-info-box {
    width: 300px;
    background-color: white;
    padding: 30px 25px;
    height: fit-content;
}

.account-info-box h4, .account-info-box h3 {
    color: #131921;
}

.account-info-box .demi {
    font-weight: 600;
}

.account-info-box .med {
    font-weight: 500;
}

.account-info-box .loader-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
}

.account-page-wrapper .main-section .second-section form {
    padding: 45px;
    border: 1px solid RGB(54, 71, 92, 0.25);
    border-radius: 4px;
}

.account-page-wrapper .main-section .second-section form .section-1 {
    display: flex;
    flex-flow: row nowrap;
}

.account-page-wrapper .main-section .second-section form .form-section {
    width: 33%;
    margin-right: 30px;
}

.account-page-wrapper .main-section .second-section form .form-section:last-of-type {
    margin-right: 0px;
}

.account-page-wrapper .main-section .second-section form .section-2 {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.account-page-wrapper .main-section .second-section form .action-button{
    width: 165px;
    margin-right: 0px;
    background-color: #1C2C41;
    font-size: 14px;
}

.account-page-wrapper .main-section .second-section form button:disabled{
    background-color: gray;
}

.your-account{
    color: #414141;
}

.form-section .border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.form-section .border {
    border-width: 1px;
}
@media only screen and (max-width: 870px) {
    .account-page-wrapper .main-section {
        margin-right: 0;
    }

    .cart-purchase-box {
        width: 100%;
        min-width: 0 !important;
    }

    .wrap {
        flex-wrap: wrap !important;
    }

    .account-page-wrapper .user-wrapper .limit-width {
        min-width: 0;
        width: 100%;
    }

    .account-info-box {
        min-width: 0;
        max-width: none;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .account-page-wrapper .main-section .first-section {
        flex-flow: row wrap;
    }

    .account-page-wrapper .main-section .id-section {
        width: 100%;
        min-width: 0;
        margin-right: 0px;
    }

    .account-page-wrapper .main-section .name-section {
        width: 100%;
        min-width: 0;
        margin-right: 0px;
    }

    .account-page-wrapper .main-section .cnpj-section {
        width: 100%;
        min-width: 0px;
    }

    .account-page-wrapper .main-section .second-section form {
        padding: 20px;
    }

    .account-page-wrapper .main-section .second-section form .section-1 {
        flex-flow: row wrap;
    }

    .account-page-wrapper .main-section .second-section form .form-section {
        width: 100%;
        margin-right: 0px;
    }

    .account-page-wrapper .main-section .second-section form .action-button{
        width: 100%;
    }
}

body {
    font-family: SKF Chevin OT, sans-serif;
  }
  .container {
    display: flex;
    max-width: 690px;
    flex-direction: column;
    margin: 0 auto;
  }
  .title {
    color: #414141;
    letter-spacing: 0.33px;
    align-self: center;
    font: 700 22px SKF Chevin OT, sans-serif;
  }
  .section {
    border-radius: 4px;
    border: 1px solid rgba(198, 198, 198, 1);
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    padding: 34px 30px;
  }
  @media (max-width: 991px) {
    .section {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  .section-title {
    color: #151e26;
    text-align: center;
    letter-spacing: 0.25px;
    font: 500 18px/139% SKF Chevin OT, sans-serif !important;
  }
  @media (max-width: 991px) {
    .section-title {
      max-width: 100%;
    }
  }
  .label-type {
    color: #151e26;
    letter-spacing: 0.25px;
    margin-top: 37px;
    font: 700 18px SKF Chevin OT, sans-serif;
  }
  @media (max-width: 991px) {
    .label {
      max-width: 100%;
    }
  }
  .input-group {
    border-radius: 2px;
    border: 1px solid rgba(19, 25, 33, 1);
    align-self: start;
    display: flex;
    margin-top: 10px;
    gap: 8px;
    font-size: 16px;
    color: #2f2f2f;
    font-weight: 500;
    line-height: 130%;
    padding: 10px 20px;
  }
  @media (max-width: 991px) {
    .input-group {
      white-space: initial;
    }
  }
  .input-label {
    flex: 1;
  }
  .img {
    aspect-ratio: 1.82;
    object-fit: contain;
    object-position: center;
    width: 11px;
    margin: auto 0;
  }
  .message {
    color: #151e26;
    letter-spacing: 0.25px;
    margin-top: 37px;
    font: 600 18px SKF Chevin OT, sans-serif;
  }
  @media (max-width: 991px) {
    .message {
      max-width: 100%;
    }
  }
  .textarea {
    border-radius: 4px;
    border: 1px solid rgba(19, 25, 33, 1);
    margin-top: 10px;
    color: #494949;
    height: 150px;
    justify-content: center;
    padding: 10px 20px 20px;
    font: 500 16px/130% SKF Chevin OT, sans-serif;
  }
  @media (max-width: 991px) {
    .textarea {
      max-width: 100%;
    }
  }
  .file-upload {
    border-radius: 8px;
    border: 1px dashed rgba(19, 25, 33, 0.856);
    background-color: #f6f6f6;
    display: flex;
    margin-top: 37px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 28px 60px;
  }
  @media (max-width: 991px) {
    .file-upload {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  .file-upload-inner {
    display: flex;
    width: 265px;
    max-width: 100%;
    flex-direction: column;
  }
  .file-upload-title {
    color: #131921;
    letter-spacing: 0.33px;
    font: 700 18px/25px SKF Chevin Catalogue, sans-serif;
  }
  .file-upload-button {
    border-radius: 4px;
    background-color: #131921;
    color: #fff;
    letter-spacing: 0.22px;
    justify-content: center;
    margin: 27px 24px 0;
    padding: 12px 25px;
    font: 600 12px SKF Chevin Math OT, sans-serif !important;
  }
  @media (max-width: 991px) {
    .file-upload-button {
      margin: 0 10px;
      padding: 0 20px;
    }
  }
  .checkbox-group {
    justify-content: center;
    display: flex;
    margin-top: 46px;
    gap: 8px;
    font-size: 18px;
    color: #151e26;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 139%;
  }
  @media (max-width: 991px) {
    .checkbox-group {
      flex-wrap: wrap;
      margin-top: 40px;
    }
  }
  .checkbox {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
    margin: auto 0;
  }
  .checkbox-label {
    flex: 1;
  }
  .submit-button {
    justify-content: center;
    border-radius: 5px;
    background-color: #1e2b3c;
    align-self: start;
    margin-top: 37px;
    color: #fff;
    text-align: center;
    padding: 10px 28px;
    font: 600 15px/130% SKF Chevin Light, sans-serif;
  }
  @media (max-width: 991px) {
    .submit-button {
      padding: 0 20px;
    }
  }