.rating-wrapper{
    padding: 5px 0 !important;
}

.rating-wrapper p{
    padding: 0 5px !important;
    font-weight: 500;
    font-size: 12px;
    color: #5E5E5E;
}

.rating-cover{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 25px;
    width: 100px;
    opacity: 20%;
}

.loaded{
    opacity: 100%;
}

.trial{
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 100px;
}