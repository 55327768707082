.cart-wrapper {
  background-color: white;
  padding: 34px 34px 30px 34px;
  width: 100%;
  margin-right: 20px;
}

.cart-wrapper .select-multiple {
  font-size: 14px;
  font-weight: 500;
  color: #68809E;
  text-decoration: none;
  cursor: pointer;
  top: 0px !important;
}

.first-row {
  display: flex;
  flex-flow: row wrap;
  margin: 0px;
}

.first-row .first-col {
  width: 5%;
  height: 32px;
  display: flex;
  gap: 16px;
  border-right: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  border-left: 1px hidden #D8D8D8;
}

.first-row .first-col-header {
  width: 20%;
  height: 32px;
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #D8D8D8;
  border-left: 1px hidden #D8D8D8;
}

.first-row .second-col {
  width: 35%;
  height: 32px;
  display: flex;
  /* border-right: 1px solid #D8D8D8; */
  border-bottom: 1px solid #D8D8D8;
  /* border-left: 1px hidden #D8D8D8; */
}
.first-row .third-col {
  width: 30%;
  height: 32px;
  display: flex;
  /* border-right: 1px solid #D8D8D8; */
  border-bottom: 1px solid #D8D8D8;
  /* border-left: 1px hidden #D8D8D8; */
}
.first-row .fourth-col {
  width: 15%;
  height: 32px;
  display: flex;
  /* border-right: 1px hidden #D8D8D8; */
  border-bottom: 1px solid #D8D8D8;
  /* border-left: 1px hidden #D8D8D8; */
}

.cart-table .items-selected {
  display: flex;
  padding: 20px 24px 16px 24px;
  justify-content: start;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;

  border-radius: 4px 4px 0px 0px;
  border: 1px solid #d8d8d8;
  background: #f6f6f6;
}
.cart-table .cart-body {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  align-self: stretch;
  height: auto;

  border-radius: 0px 0px 4px 4px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  background: #fff;
}

.cart-table .items-selected p {
  color: #151e26;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}

.cart-wrapper .loader-wrapper {
  height: 100px;
  width: 100%;
  position: relative;
}

.cart-wrapper .price {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #151e26;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  display: flex;
  width: 306px;
  justify-content: center;
}

.cart-wrapper .total-box {
  width: 100%;
  justify-content: flex-end !important;
}

.cart-wrapper .total-box p {
  font-size: 18px;
  color: #303030;
}

.cart-wrapper .total-box .bold {
  font-weight: 700;
}

.cart-wrapper .links {
  font-size: 14px;
  font-weight: 500;
  color: #68809e;
  text-decoration: none;
  padding: 0 15px;
  cursor: pointer;
  position: relative;
}

.cart-purchase-box {
  min-width: 370px;
  background-color: white;
  padding: 34px;
  height: fit-content;
}

.cart-purchase-box .credit-title {
  font-size: 18px;
  font-weight: 300;
  color: #303030;
}

.cart-purchase-box .balance-low {
  font-size: 12px;
  font-weight: 300;
  color: #303030;
  text-align: center;
}

.cart-purchase-box .credit-card {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #b43030;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}

.cart-purchase-box .bold-error {
  font-size: 16px;
  font-weight: 600;
}

.cart-purchase-box .quote-message {
  width: 100%;
  padding: 10px;
}

.cart-purchase-box .loader-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
}

.cart-purchase-box  .swal2-popup {
  width: fit-content !important;
  min-width: 32rem;
}

.secondary {
  border: 0.8px solid #ddd;
  background: #f6f6f6;
  color: #1d1b1b;
}

@media only screen and (max-width: 1275px) {
  .cart-page-wrapper .hide-medium {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .cart-page-wrapper {
    flex-wrap: wrap !important;
  }

  .cart-page-wrapper .cart-wrapper {
    margin-right: 0;
  }

  .cart-purchase-box {
    width: 100%;
    min-width: 0 !important;
  }
}