.page-selector {
    width: fit-content;
    margin: auto;
    border-radius: 4px;
    border: 1px solid #979797;
}

.page-selector .control-buttons {
    margin: 12px 0;
    padding: 4px 12px;
    cursor: pointer;
    min-width: 40px;
}

.page-selector .control-buttons:first-of-type {
    border-right: 1px solid #D8D8D8;
}

.page-selector .control-buttons:last-of-type {
    border-left: 1px solid #D8D8D8;
}

.page-selector .control-buttons img {
    height: 12px;
    margin: auto 7px;
}

.page-selector .control-buttons p {
    font-size: 14px;
}

.page-selector .pages-section {
    width: fit-content;
}

.page-selector .page-num {
    width: 1vw;
    min-width: 35px;
    max-width: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-selector .active {
    border: 1px solid #232F3E;
}