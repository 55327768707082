.cards-box{
    justify-content: space-between;
    flex-wrap: nowrap;
}

.cards-box .couple-wrapper{
    justify-content: space-between;
    width: 48%;
}

.card{
    width: 25%;
    max-height: 358px;
    height: 30vw;
    margin-right: 20px;
    background-color: white;
    padding: 27px 18px;
}

.card:last-of-type{
    margin-right: 0;
}

.card .first{
    width: 100%;
    height: 20%;
    padding: 0 10px;
    min-height: 64px;
}

.card .image-holder{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 70%;
}

.card .third{
    width: 100%;
    height: 10%;
    display: flex;
}

.third p{
    font-weight: 300;
    font-size: 14px;
    color: #232F3E;
    align-self: flex-end;
}

@media only screen and (max-width: 900px) {
    .card .first{
        min-height: 45px;
        height: 17%;
    }

    .card .image-holder{
        height: 75%;
    }
}

@media only screen and (max-width: 769px) {
    .cards-box{
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .card:nth-child(even) {
        margin-right: 0;
    }

    .card:nth-child(odd) {
        margin-right: 20px;
    }

    .card{
        max-height: 380px;
        max-width: none;
        width: 48%;
        height: 60vw;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 520px) {
    .cards-box{
        display: block;
    }

    .card{
        min-height: 358px;
        width: 100%;
        height:60vw;
        margin-bottom: 20px;
    }
}