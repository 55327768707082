.order-details-box {
    
}

.order-details-box .title-box {
    align-items: center;
    cursor: pointer;
}

.order-details-box .title {
    font-size: 16px;
    font-weight: 700;
    color: #414141;
    margin-left: 10px;
}

.order-details-box .lines-box{
    align-items: center;
}

.order-details-box .lines-box .data{
    width: 75%;
    padding: 20px;
}


.order-details-box .lines-box img{
    max-width: 150px;
}
.no-shrink{
    flex: 0 0 auto;
}

@media only screen and (max-width: 817px) {

    .order-details-box .lines-box img{
        width: 100%;
        max-width: 200px;
        order: 1 !important;
        margin: 20px auto;
    }

    .order-details-box .lines-box .data{
        width: 100%;
        order: 2;
        padding: 0;
    }
}