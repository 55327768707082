.product-info-wrapper{
    max-width: 480px;
    min-width: 230px;
    margin-right: 40px;
    min-height: 90px;
    flex: 1;
}

.product-info-wrapper h2{
    font-weight: 600;
    color: #151515;
}

.product-info-wrapper p{
    margin: 0;
    padding: 0;
    position: relative;
    top: 4px !important;
}


.product-info-wrapper  .price-desc {
    justify-content: center;
    border-radius: 4px;
    background-color: #cce4fe;
    color: #004085;
    padding: 6px 22px 6px 10px;
    font: 400 12px SKF Chevin Catalogue, -apple-system, Roboto,
        Helvetica, sans-serif;
        height: 28px;
    margin-left: 10px;
    width: 55%;
    height: 70%;
    }
    

.product-info-wrapper .brand-name{
    font-weight: 300;
    font-size: 22px;
    color: #547092;
}

.product-info-wrapper .price{
    font-weight: 500;
    font-size: 19px;
    color: #B12704;
}

.product-info-wrapper .info-img{
    width: 20px;
    height: 20px;
    margin-left: 12px;
}

.product-info-wrapper .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}

.product-info-wrapper .taxa-info-box{
    position: absolute;
    left: 47%;
    z-index: 1000;
    width: 240px;
    padding: 26px 20px 26px 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    margin-top: 30px;
    }


.product-info-wrapper .taxa-info-box .price{
    margin-right: 10px;
    color: black;
    font-weight: 400;
}

.product-info-wrapper .mt-0{
    margin-top: 0px !important;
}

@media only screen and (max-width: 768px){
    .product-info-wrapper{
        max-width: none;
        margin-right: 0px;
        margin-bottom: 40px;
        width: 100%;
        flex: 1 1 auto;
    }
    
}