.maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
    background-color: #f2f2f2; /* Light gray background */
    color: #333; /* Dark text for contrast */
    font-family: Arial, sans-serif; /* Simple, readable font */
}

.maintenance-container h1 {
    font-size: 2.4em; /* Large font size for the main message */
    margin-bottom: 0.5em; /* Spacing between the main message and the subtitle */
}

.maintenance-container p {
    font-size: 1.2em; /* Slightly larger font size for the subtitle */
    width: 50%;
    line-height: 30px;
}