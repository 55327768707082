.purchase-box{
    width: 350px;
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    padding: 25px;
    min-height: 90px;
}

.purchase-box .justify-between{
    justify-content: space-between;
}

.purchase-box h3{
    color: #303030;
}

.purchase-box .price{
    font-weight: 700;
    font-size: 19px;
}

.purchase-box .min-desc{
    font-size: 15px;
    line-height: 20px !important;
}

.purchase-box .cotar_btn{
    margin-right: 4px;
    margin-top: 0px;
    font-size: 14px;
    height: 30px;
    background-color:#3c4f67;
}

.purchase-box .cotar_btn:hover {
    background-color: #0f1419;
}

.bell-button {
    position: relative;
    background-color: #3c4f67;
    border: none;
    border-radius: 30%;
    /* padding: 10px; */
    cursor: pointer;
    height: 30px;
    width: 30px;
}

.bell-button svg {
    fill: white;
    height: 18px;
    width: 18px;
    margin-top: 4px;
}

.bell-button:hover {
    background-color: #0f1419;
}

.tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    margin-top: 6px;
}

.bell-button:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.disabled {
    background-color: #c0c0c0;
    opacity: 0.5;
    color: #303030;
    cursor: text;
}

.purchase-box .shipping{
    font-weight: 300;
    font-size: 18px;
    color: #272727;
}

.purchase-box .shipping-date{
    font-weight: 700;
    font-size: 18px;
    color: #303030;
    text-transform: capitalize;
}

.purchase-box .shipping-address{
    font-weight: 300;
    font-size: 18px;
    color: #303030;
}

.purchase-box .in-stock{
    font-weight: 500;
    font-size: 22px;
    color: #067D62;
}

.purchase-box .out-of-stock{
    font-weight: 500;
    font-size: 22px;
    color: rgb(177, 39, 4);
}

.purchase-box .loader-wrapper{
    position: relative;
    width: 90%;
    margin: auto;
    height: 90px;
}

.purchase-box .quote-message{
    width: 100%;
    padding: 10px;
}

.purchase-box .stock-label-success {
    background-color: #289067; 
    color: #FFFFFF;           
    padding: 6px 12px;       
    border-radius: 5px;      
    text-align: center;        
    display: inline-block;     
}

.purchase-box .stock-label-danger {
    background-color: #8F2C2C; 
    color: #FFFFFF;           
    padding: 6px 12px;       
    border-radius: 5px;      
    text-align: center;        
    display: inline-block;     
}

.purchase-box .stock-label-warning {
    background: #BA983C;
    color: #FFFFFF;           
    padding: 6px 12px;       
    border-radius: 5px;      
    text-align: center;        
    display: inline-block;     
}


.purchase-box .parent-div {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0.25rem; /* Tailwind's default border-radius */
    background-color: #bfdbfe; /* Tailwind's bg-blue-200 */
    gap: 0.375rem; /* Tailwind's gap-1.5 */
    padding-left: 0.625rem; /* Tailwind's pl-2.5 */
    padding-top: 0.625rem; /* Tailwind's py-2.5 */
    padding-bottom: 0.625rem; /* Tailwind's py-2.5 */
}

.purchase-box .parent-div img {
    /* aspect-square is not a direct CSS property. It's used to maintain aspect ratio in Tailwind. */
    object-fit: contain;
    object-position: center;
    width: 18px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.purchase-box .parent-div .text-div {
    color: #004085; /* Tailwind's text-sky-800 */
    font-size: 0.75rem; /* Tailwind's text-xs */
    line-height: 0.75rem; /* Tailwind's leading-3 */   
}

.purchase-box .datepicker{
    border-color: transparent;
    color: transparent;
}

.purchase-box .datepicker:focus{
    border-color: transparent;
    color: transparent;
}

.purchase-box .datepicker:active{
    border-color: transparent;
    color: transparent;
}
.purchase-box .datepicker::placeholder {
    color: transparent;
}