.tables-wrapper {
    justify-content: space-between;
}

.tables-wrapper .product-table-wrapper .product-table-scroller {
    max-height: 500px;
    overflow: auto;
}

.tables-wrapper .product-table-wrapper .product-table-scroller-short{
    max-height: 435px;
    overflow: auto;
}

.tables-wrapper .product-table-wrapper  {
    overflow: hidden !important;
}

.tables-wrapper .product-table-wrapper table {
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
}

.tables-wrapper .product-table-wrapper table td:last-of-type{
    border-bottom: 1px solid #E6E6E682;
}

.tables-wrapper .search-applications{
    width: 99%;
    display: block;
    margin: auto !important;
    margin-bottom: 20px !important;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    padding: 13px 11px;
    color: #414141;
    font-size: 16px;
}

.tables-wrapper .search-applications::placeholder{
    font-size: 14px;
    color: #ACACAC;
}

@media only screen and (max-width: 1024px) {

    .tables-wrapper .product-table-wrapper{
        width: 100% !important;
        margin-top: 40px;
    }

}