.magnifier {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 385px;
    max-height: 385px;
    width: 30vw;
}

.magnifier:hover {
    cursor: crosshair;
    background-size: 1200px !important;
}

@media only screen and (max-width: 503px) {
    .magnifier {
        width: 100%;
    }
}
@media only screen and (max-width: 1024px) {
    .magnifier {
        margin: auto;
        width: 100%;
        height: 100vw;
    }
}