.validar-page{
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #131921;
}

.validar-page .form-container{
    width: 90%;
    max-width: 475px;
    margin: auto;
}

.validar-page .form-container h3{
    color: #FFFFFF;
    text-align: center;
}

.validar-page .form-container form{
    padding: 50px 25px;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.validar-page .form-container form .loader-wrapper{
    position: relative;
    width: 100%;
    height: 80px;
}

.validar-page .form-container form .label{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: #131921 !important;
    margin-bottom: 5px;
    padding: 0;
}

.validar-page .form-container form input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid #979797;
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    padding: 10px;
    margin-bottom: 15px;
}

.validar-page .form-container form:focus-within{
    border: 0 !important;
}

.validar-page .form-container form input[type=submit]{
    background-color: #232F3E;
    margin-top: 5px;
    color: white;
}

.validar-page .form-container form .disclaimer{
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #303030;
}

.validar-page .form-container form h5{
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: #232F3E;
}
.validar-page .form-container form p{
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #232F3E;
}

.validar-page .form-container form .error{
    font-size: 16px;
    color: red;
    text-align: center;
}