.seguranca-content {
    width: 75%;
    margin: auto;
}

.seguranca-content h4{
    font-size: 22px !important;
    font-weight: 600;
    color: #171717;
}

.password-change .loader-wrapper {
    position: relative;
    width: 60%;
    height: 90px;
}


.twofa-info{
    padding: 20px;
    background-color: #DBEDFF;
    font-size: 14px !important;

}
.seguranca-content .password-change {
    border: 1px solid RGB(54, 71, 92, 0.25);
    border-radius: 4px;
    padding: 35px;
    display: flex;
    flex-direction: column;
}

.password-change .paragraph{
    color: #303030;
    font-size: 18px;
    line-height: 27px;;
    font-weight: 300;
}
.password-change .senha {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    width: 95%;
}

.password-change .senha label {
    color: #171717;
    font-size: 18px;
    font-weight: 600;
    padding: 1% 0;
}

.password-change .senha input {
    border-radius: 3px;
    height: 50px;
    background-color: white;
    padding: 3%;
    border: 1px solid RGB(54, 71, 92, 0.25);
    font-size: 20px;
    font-weight: 300;
    width: 60%;
}
.password-change .senha p {
    text-align: right;
    font-weight: 300;
    color: #303030;
    margin-top: 1%;
}

.your-account{
    color: #414141;
}
@media (max-width: 768px) {

    .seguranca-content .password-change {
        padding: 20px;
    }

    .seguranca-content {
        width: 100%;
        padding: 0 0;
    }

    .password-change .senha,
    .password-change .senha input {
        width: 100%;
    }

    .password-change .loader-wrapper {
        margin: auto;
        width: 100%;
    }
}