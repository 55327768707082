.cart-item-wrapper {
  position: relative;
  padding: 0px;
  flex-flow: row wrap;
  margin: 0px;
}

.cart-item-wrapper .loader-wrapper {
  height: 100px;
}

.cart-item-wrapper .checkbox-holder {
  height: 100%;
  display: flex;
  align-items: center;
}

.cart-item-wrapper .first-section {
  width: 100%;
}

.cart-item-wrapper .first-col {
  width: 5%;
  height: 200px;
  display: flex;
  gap: 16px;
  align-items: center;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
}

.cart-item-wrapper .second-col {
  width: 50%;
  height: 200px;
  gap: 16px;
  display: flex;
  align-items: center;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
}
.cart-item-wrapper .price-desc {
  position: absolute;
  z-index: 1;
  justify-content: center;
  border-radius: 4px;
  background-color: #cce4fe;
  color: #004085;
  padding: 6px 22px 6px 10px;
  font: 400 12px SKF Chevin Catalogue, -apple-system, Roboto, Helvetica,
    sans-serif;
  height: 28px;
  margin-left: 10px;
  width: auto;
  height: auto;
}
.cart-item-wrapper .third-col {
  width: 30%;
  height: 200px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
  flex-direction: column;
}

.cart-item-wrapper .fourth-col {
  width: 15%;
  height: 200px;
  gap: 16px;
  display: flex;
  align-items: center;
  border-right: 1px hidden #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
}

.cart-item-wrapper img {
  max-height: 80px;
  max-width: 80px;
  margin-left: 10px;
}

.cart-item-wrapper .item-info {
  max-width: 550px;
  /* margin-left: 30px; */
  flex: 1 1 auto;
}

.cart-item-wrapper .item-info .name {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #303030;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.cart-item-wrapper .min-desc {
  font-size: 15px;
  line-height: 20px !important;
}

.cart-item-wrapper .item-info .in-stock {
  font-size: 13px;
  font-weight: 600;
  /* width: 101px; Remove the fixed width */
  min-width: 27px; /* Optional: Ensure it has a minimum width if needed */
  height: 27px;
  display: inline-flex; /* Change from flex to inline-flex */
  padding: 4px 8px;
  align-items: center; /* Change from flex-start to center for vertical centering */
  grid-gap: 10px;
  gap: 10px;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
  /* background: #289067; Uncomment or adjust as needed */
}
.cart-item-wrapper .partial-stock {
  width: 170px !important;
}
.amount-green {
  background: #289067;
}
.amount-red {
  background: #8f2c2c;
}
.amount-yellow {
  background: #ba983c;
}

.cart-item-wrapper .price {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #303030;
  gap: 5px;
  width: 100%;
  padding-left: 16px;
  padding-right:16px;
  justify-content: space-between;;
  align-items: center;
}

.cart-item-wrapper .margin-left {
  margin-left: 17px;
  position: static;
}

.cart-item-wrapper .links {
  font-size: 14px;
  font-weight: 500;
  color: #68809e;
  text-decoration: none;
  border-left: 1px solid #d8d8d8;
  padding: 0 15px;
  cursor: pointer;
}

.cart-item-wrapper .info-img {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.hide-big {
  display: none;
}

.hide-small {
  display: flex;
}

/* @media only screen and (max-width: 1275px) {
  .cart-item-wrapper .margin-left {
    margin-left: 0 !important;
  }
  .cart-item-wrapper .checkbox-holder {
    display: none;
  }
  .cart-item-wrapper .qtt-holder {
    margin-bottom: 10px;
  }
  .cart-item-wrapper .first-section {
    width: 100%;
  }
  .hide-small {
    display: block;
  }
  .cart-item-wrapper .links {
    font-size: 12px;
    border-left: 0;
    padding: 0 10px 0 0;
  }
  .cart-item-wrapper .price {
    width: 100%;
    text-align: right;
  }
} */

/* @media only screen and (max-width: 1024px) {
  .cart-item-wrapper img {
    max-height: 90px;
    max-width: 90px;
  }
  .cart-item-wrapper .item-info .name {
    font-size: 16px;
    line-height: 20px;
  }
  .cart-item-wrapper .item-info .in-stock {
    font-size: 14px;
  }
  .cart-item-wrapper .qtt-holder .margin-left {
    width: 100%;
  }
  .cart-item-wrapper .price {
    font-size: 16px;
  }
} */

/* @media only screen and (max-width: 700px) {
  .cart-item-wrapper img {
    max-height: 50px;
    max-width: 50px;
  }
  .cart-item-wrapper .item-info .name {
    font-size: 14px;
    line-height: 16px;
  }
  .cart-item-wrapper .item-info .in-stock {
    font-size: 12px;
  }
  .cart-item-wrapper .price {
    font-size: 14px;
  }
  .hide-big {
    display: block;
  }
  .hide-small {
    display: none;
  }
}   */