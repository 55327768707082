.hero-wrapper {
    position: relative;
    z-index: 0;
}

.hero-wrapper .arrow {
    max-height: 44px;
    min-height: 22px;
    height: 2vw;
}

.float-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 250px;
    max-height: 490px;
    height: 50vw;
    width: 100%;
    z-index: -1;
    padding: 0px 80px;
}

.hero-buffer {
    min-height: 140px;
    max-height: 265px;
    height: 30vw;
    float: none;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    font-size: 40px;
    color: white;
    padding: 15px;
    top: 19%;
}

.prev {
    left: 15px;
}

.next {
    right: 15px;
}

.image_hero {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 70%, transparent 100%);
}

.zero-top {
    top: 0 !important;
}

.image_hero img {
    left: -9999px;
    max-width: none;
    width: 100%;
    right: -9999px;
    margin: auto;
    position: absolute;
}

.hero-wrapper .image-wrapper {
    height: 600px;
    position: relative;
    max-width: 1400px;
    margin: auto;
}

@media only screen and (max-width: 1024px) {
    .hero-wrapper .arrow {
        min-height: 22px;
    }
}

@media only screen and (max-width: 768px) {
    .hero-wrapper .arrow {
        min-height: 15px;
    }
    .float-wrapper img {
        min-width: 460px;
        width: 100%;
        height: auto !important;
    }
    .prev, .next {
        top: 9%;
    }
    .prev {
        left: 5px;
    }
    .next {
        right: 5px;
    }
}