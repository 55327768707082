.icon-text {
    display: flex;
    flex-flow: column;
    cursor: pointer;
    text-align: center;
    text-transform: capitalize;
}

.icon-text p {
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
}

.icon-text .dropdown {
    position: relative;
    display: inline-block;
}

.icon-text .dropdown-content .wrapper {
    background-color: #FFFFFF;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.icon-text .dropdown-content {
    display: none;
    position: absolute;
    padding-top: 15px;
    background-color: transparent;
    z-index: 1;
}

.icon-text .dropdown-content p {
    color: #232F3E;
    padding: 12px 16px;
    text-decoration: none;
    text-align: left;
    display: block;
}

.icon-text .dropdown-content p:hover {
    background-color: #ddd;
}

.icon-text .dropdown:hover .dropdown-content {
    display: block;
}

.icon-text .dropdown-content:hover {
    display: block;
}

.icon-text .mobile-dropdown:focus .dropdown-content {
    display: block;
}