.change-password-page{
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background-color: white;
}

.change-password-page .form-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 120px;
    margin: auto;
    max-width: 1440px;
}

.change-password-page .section-one{
    width: 90%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 100px;
}

.b2e-color{
    background-color: #127BC1;
}

.b2b-color{
    background-color: #131921;
}

.change-password-page .section-two{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;    
}

.change-password-page .section-two .blue-link{
    color:#142F4D;
    text-decoration: underline;
    font-weight: 700;
}

.change-password-page .section-two h3{
    margin-bottom: 60px;
}

.change-password-page .section-two .description{
   font-size: 24px;
   line-height: 31px;
   margin-bottom: 16px;
}

.change-password-page .form-container h1{
    color: #FFFFFF;
    text-align: center;
    font-size: 60px;
}

.change-password-page .form-container form{
    padding: 50px 25px;
    background-color: #FFFFFF;
    border-radius: 8px;
    min-width: 350px;
}

.change-password-page .form-container form .loader-wrapper{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
}

.change-password-page .form-container form .loader-wrapper h3{
    color: white;
}

.change-password-page .info-message{
    font-size: 15px !important;
}

.change-password-page .title{
    font-size: 38px !important;
    color: #131921;
}

.change-password-page .form-container form .label{
    font-size: 14px;
    line-height: 18px;
    color: #131921 !important;
    margin-bottom: 5px;
    padding-left: 0;
    word-break: break-all;
    white-space: normal;
}

.change-password-page .form-container form .input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid #979797;
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    padding: 10px;
    margin-bottom: 5px;
}

.change-password-page .form-container form:focus-within{
    border: 0 !important;
}

.change-password-page .form-container form input[type=submit]{
    margin-top: 5px;
    color: white;
}

.change-password-page .form-container form .disclaimer{
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #303030;
}

.change-password-page .form-container form h5{
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: #232F3E;
}
.change-password-page .form-container form p{
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #232F3E;
}

.change-password-page .form-container form a{
    font-size: 12px;
    line-height: 20px;
    font-weight: 900;
    color: #232F3E;
    text-decoration: none !important;
}

.change-password-page .form-container form .error{
    font-size: 14px;
    color: #81263b;
    margin-top: 5px;
}

.change-password-page .action-button{
    border-radius: 8px !important;
    background-color: black !important;
    min-height: 55px;
}

.change-password-page .pass-holder{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-width: 400px !important;
    min-height: 55px;
}

.change-password-page .mask-password,
.change-password-page .mask-password:focus{
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    border: 0 !important;
    outline: none;
}

.change-password-page .form-container form .disabled{
    background-color: #D6D6D6 !important;
    border: 1px solid #D6D6D6;
    color: white !important;
}

.change-password-page .form-container form .light-grey{
    color: #797979 !important;
}

.change-password-page .form-container form .error-input{
    border: 1px solid #81263b !important;
}

.change-password-page .rotator-wrapper{
    height: 200px;
}

@media only screen and (max-width: 900px) {
    .change-password-page .form-container{
        flex-flow: column nowrap;
        padding: 0;
    }
    .change-password-page .section-one{
        width: 100%;
        height: 70%;
        margin-right: 0;
    }
}

@media only screen and (max-width: 450px) {
    .change-password-page .form-container h1{
        font-size: 40px;
    }
}