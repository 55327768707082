.img-selector-wrapper{
    max-width: 480px;
    margin-right: 40px;
    flex: 1;
    flex-wrap: nowrap;
    min-height: 90px;
}

.frame{
    max-width: 385px;
    max-height: 385px;
    width: 30vw;
}

.frame div img{
    max-width: 385px;
    max-height: 385px;
}

.frame div:hover img{
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
}

.img-holder{
    max-height: 385px;
    height: 30vw;
    margin-right: 40px;
    overflow-y: auto;
}

.img-holder .selector-images{
    max-width: 55px;
    max-height: 55px;
    border: 1px solid #ECECEC;
    border-radius: 2px;
    margin-bottom: 10px;
    overflow: hidden;
}

.img-holder .selector-images:focus, .img-holder .selector-images:active, .img-holder .focused{
    border: 1px solid #232F3E;
}

.img-holder .selector-images img{
    max-width: 100%;
    max-height: 100%;
}

.magnifier-container{
    z-index: 100;
}

@media only screen and (max-width: 503px){
    .img-holder{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-right: 0px;
        width: 100%;
        order: 2;
        height: auto;
        margin-top: 20px;
    }

    .img-selector-wrapper{
        flex-wrap: wrap;
    }

    .frame{
        width: 100%;
    }
}

@media only screen and (max-width: 1024px){

    .img-selector-wrapper{
        max-width: none;
        width: 100%;
        margin-right: 0px;
        flex: 1 1 auto;
        margin-bottom: 40px;
    }

    .frame{
        margin: auto;
        width: 100%;
        height: 100vw;
    }
    
}