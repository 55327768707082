.quantity-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quantity-wrapper p{
    margin-right: 20px;
}

.quantity-wrapper .min-width{
    width: 150px;
}

.quantity-button{
    width: 38px;
    height: 38px;
    background-color: #F6F6F6;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.quantity-button > *{
    margin: auto;
}

.quantity-wrapper input{
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    width: 70px;
    text-align: center;
    padding-top: 6px;
    border: 0;
}