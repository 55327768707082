.exchange-page .video{
   width: 90vw !important;
   height: 52.5vw !important; 
   margin: auto !important;
   max-width: 1200px !important;
   max-height: 700px !important;
}

.exchange-page h2{
   margin-bottom: 20px;
}

.exchange-page p{
   margin-bottom: 15px;
}

.exchange-page .MsoNormal{
   margin-bottom: 10px
}