.cc-section {
    position: relative;
    margin-top: 20px;
}

.cc-section .cover {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 60%;
    z-index: 500;
}
.cc-section .card-input{
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #A3A3A3;
    margin: 0 10px 20px 0;
    font-size: 18px;
}

.cc-section .w-full{
    width: 100%;
}

.cc-section .w-half{
    width: 49%;
}

.cc-section .w-quarter{
    width: 49%;
}

.cc-section .placeholder{
    color: #bdbbbb !important;
}

@media only screen and (max-width: 870px) {
    .cc-section .card-input{
    }

    .cc-section .w-full{
        width: 100%;
    }
    
    .cc-section .w-half{
        width: 100%;
    }
    
    .cc-section .w-quarter{
        width: 49%;
    }
    .cc-section {
        width: 100% !important;
        margin-right: 0px;
    }
    
}