.container {
  display: flex;
  width: 460px;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background: #fff;
}

.texts {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  
}
.border-bottom{
  border-bottom: 1px solid #d4d4d4;
}
.product-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-bottom: 16px;
}
.deadline-info {
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
}
.h1{
  color: #151e26;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  align-self: stretch;
}
p {
  font-family: "SKF Sans";
  font-style: normal;
  line-height: 130%; /* 41.6px */
}

.title {
  color: #151e26;
  font-size: 32px;
  font-weight: 700;
}

.subtitle {
  color: #888;
  font-size: 20px;
  font-weight: 400;
}
