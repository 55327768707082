

.product-table-row td {
    border-top: 1px solid #E6E6E682;
    border-left: 1px solid #E6E6E682;
    border-right: 1px solid #E6E6E682;
    padding: 12px 22px;
}


.product-table-row td, .product-table-row td p {
    font-weight: 300;
    font-size: 15px;
    color: #414141;
    line-height: 20px;
}

.product-table-row .table-col1 {
    background-color: #F9F9F9;
    font-weight: 500;
    font-size: 15px;
    color: #414141;
    padding: 12px 17px;
    height: 100% !important;
    width: 40%;
}

.hidden {
    max-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cell-arrow{
    justify-content: space-between;
    align-items: center;

}


@media only screen and (max-width: 1024px) {

    .product-table-row .table-col1 {
        width: 50%;
    }

}