/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

@font-face {
  font-family: 'SKF';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinOT-Bold.otf');
}

@font-face {
  font-family: 'SKF';
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinOT-DemiBold.otf');
}

@font-face {
  font-family: 'SKF';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinOT-Medium.otf');
}

@font-face {
  font-family: 'SKF';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinOT-Light.otf');
}




@font-face {
  font-family: 'SKFNOOT';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinBold.ttf');
}

@font-face {
  font-family: 'SKFNOOT';
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinDemiBold.ttf');
}

@font-face {
  font-family: 'SKFNOOT';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinMedium.ttf');
}

@font-face {
  font-family: 'SKFNOOT';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinLight.ttf');
}

html, body {
  width: 100%;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  font-family: 'SKF';
  line-height: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* WRAPPERS */

.section-wrapper {
  max-width: 1400px;
  margin: auto;
  overflow: hidden;
  padding: 10px 30px;
}

.section-wrapper-xsmall{
  max-width: 660px;
  margin: auto;
  padding: 10px 30px;
}

.section-wrapper-small{
  max-width: 970px;
  margin: auto;
  padding: 10px 30px;
}
.section-wrapper-medium{
max-width: 1200px;
margin: auto;
padding: 10px 30px;
}

.relative{
  position: relative;
}

.flex-row {
  display: flex;
  flex-flow: row wrap;
}

.flex-grow{
  flex-grow: 1 !important;
}

.flex-col {
  display: flex;
  flex-flow: column wrap;
}

.flex-row-nowrap {
  display: flex;
  flex-flow: row nowrap;
}

.flex-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-left {
  justify-content: left;
}

.align-center{
  align-items: center;
}

.align-bottom{
  align-items: flex-end;
}

.flex-one{
  flex: 1;
}

.page-wrapper-grey, .page-wrapper-white{
  padding-bottom: 100px;
}

.page-wrapper-grey{
  background-color: #F1F1F1;
}

.page-wrapper-white{
  background-color: white;
}

/* WRAPPERS-END */

/* MARGIN */

.flex-1{
  flex:1;
}

.w-10{
  width: 10%;
}
.w-15{
  width: 15%;
}

.w-quarter{
  width: 25%;
}

.w-third{
  width: 33%;
}

.w-half{
  width: 50%;
}
.w-70{
  width: 70%;
}

.w-full{
  width: 100%;
}

.mt-1{
  margin-top: 0.25rem;;
}

.mt-2{
  margin-top: 0.5rem;;
}

.mt-3{
  margin-top: 0.75rem;;
}

.mt-4{
  margin-top: 1rem;;
}

.mt-5{
  margin-top: 1.25rem;;
}

.mb-1{
  margin-bottom: 0.25rem;;
}

.mb-2{
  margin-bottom: 0.5rem;;
}

.mb-3{
  margin-bottom: 0.75rem;;
}

.mb-4{
  margin-bottom: 1rem;;
}

.mb-5{
  margin-bottom: 1.25rem;;
}

.mr-1{
  margin-right: 0.25rem;;
}

.mr-2{
  margin-right: 0.5rem;;
}

.mr-3{
  margin-right: 0.75rem;;
}

.mr-4{
  margin-right: 1rem;;
}

.mr-5{
  margin-right: 1.25rem;;
}

.pr-1{
  padding-right: 0.25rem;;
}

.pr-2{
  padding-right: 0.5rem;;
}

.pr-3{
  padding-right: 0.75rem;;
}

.pr-4{
  padding-right: 1rem;;
}

.pr-5{
  padding-right: 1.25rem;;
}

.h-30{
  height: 30px;
}

.w-20p{
  width: 20%;
}

.margin-auto {
  margin: auto;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

/* MARGIN-END */

/* PADDING */

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

/* PADDING-END */

/* WIDTH */

.one-quarter {
  width: 25%;
}

.one-third {
  width: 33%;
}

.one-half {
  width: 50%;
}

.two-thirds {
  width: 66%;
}

.three-quarters {
  width: 75%;
}

.one-whole {
  width: 100%;
}

/* WIDTH-END */

/* FONTS */

.bold{
  font-weight: 700 !important;
}

.blue{
  color: #195c8c !important;
}

.pointer{
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, p, a, span, input::placeholder{
  margin: 0;
  padding: 0;
  position: relative;
  top: 2px !important;
}

h2 {
  font-size: 28px !important;
  color: #414141;
}

h3 {
  font-size: 24px !important;
  color: #414141;
}

h4 {
  font-size: 18px !important;
  color: #414141;
}

p {
  font-weight: 300;
  font-size: 18px;
  color: #303030;
}

.uppercase{
  text-transform: uppercase;
}

.underline{
  text-decoration: underline;
}

hr{
  border: 0;
  border-top: 1px solid #afafaf;
}

/* FONTS-END */

.reset-spacing {
  margin: 0 !important;
  padding: 0 !important;
}

.reset{
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  text-decoration: none;
}

.action-button{
  height: 45px;
  background-color:  #131921;
  color: white;
  font-weight: 600;
  font-size: 16px;
  border: 0;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
}

button{
  cursor: pointer;
}

.upside-down{
  transform: rotate(180deg);
}

.disabled{
  background-color: #C6C6C6 !important;
  color: #5f5f5f !important;
  cursor:default;
}

.error{
  color: #B12704 !important;
}

.red{
  color: #BF3636;
}

.green{
  color: #1C8870;
}

.blue{
  color: #134CD8;
}

.rotate{
  transform: rotate(90deg);
}

.rotate-180{
  transform: rotate(180deg);
}

.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right;
}

.temp-border-red{
  border: 1px solid red;
}

.pointer{
  cursor: pointer;
}

.hide{
 display: none !important;   
}

.normal-weight{
  font-weight: normal !important;
}

.grey-font{
  color: #414141 !important;
}

.display-block{
  display: block;
}

@media only screen and (max-width: 900px) {
  h3 {
    font-size: 20px !important;
  }
  h2 {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 425px) {

  .section-wrapper{
    padding: 10px;
  }

}

.swal2-popup{
  max-width: 40% !important;
}

/* LABEL STYLE */
.label{
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: .4em .6em .3em;
  font-weight: 700;
  line-height: 1;
  color: #fff !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  border: 1px solid transparent;
}

.label-special {
  background-color: #FFFFFF;
  color: #142F4D !important;
  border-color: #142F4D !important;
}

.label-default {
  background-color: #777;
}

.label-primary {
  background-color: #337ab7;
}

.label-success {
  background-color: #3f973f;
}

.label-info {
  background-color: #d88200;
}

.label-warning {
  background-color: #142F4D;
}

.label-danger {
  background-color: #d9534f;
}

.label-yellow {
  background-color: #ba983c;
}
