.search-page .sorting-section {
    padding: 12px 30px;
    border-bottom: 1px solid #D8D8D8;
    box-shadow: 0 1px 8px #dcdcdc;
}

.search-page .sorting-section p {
    font-size: 16px;
    font-weight: 600;
}

.search-page .sorting-section .mobile-filter-button{
    cursor: pointer;
}

.search-page .sorting-section .mobile-filter-button img{
    max-height: 15px;
    margin-right: 5px;
}

.search-page .sorting-section .sorting{
    font-weight: 300;
}

.search-page .sorting select, .search-page .sorting select > * {
    font-size: 16px;
    font-weight: 300 !important;
    border: 0;
}

.search-page .sorting select:focus{
    outline: 0;
}

.search-page .relative{
    position: relative;
}

.search-page .filters-section {
    position: relative;
    min-width: 280px;
    max-width: 280px;
    border-right: 2px solid #D8D8D8;
    padding: 30px;
}

.search-page .filters-section .close{
    position: absolute;
    top: 25px;
    right: 25px;
    width: 25px;
    cursor: pointer;
}

.search-page .filters-section .active {
    font-weight: 500 !important;
}

.search-page .filters-section .categories {
    margin-left: 20px;
}

.search-page .filters-section .categories h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 3px;
    cursor: pointer;
}

.search-page .filters-section .categories p {
    font-size: 15px;
    font-weight: 300;
    margin: 4px 0 3px 3px;
    cursor: pointer;
}

.search-page .filters-section .prices {
    margin-left: 20px;
}

.search-page .filters-section .prices p {
    font-size: 16px;
    margin-bottom: 5px;
    cursor: pointer;
}

.search-page .filters-section .manual-price-box .range-box {
    padding: 10px;
    width: 32%;
    border: 1px solid #232F3E;
    border-radius: 4px;
}

.search-page .filters-section .manual-price-box .range-box p {
    flex: 0;
    font-size: 14px;
    margin-right: 2px;
    position: relative !important;
    top: 5px !important;
}

.search-page .filters-section .manual-price-box .range-box input {
    width: 100%;
    border: 0;
    font-size: 14px;
}

.search-page .filters-section .manual-price-box .range-box input:focus {
    outline: 0;
    border: 0;
}

.search-page .filters-section .manual-price-box button {
    background-color: #232F3E;
}

.search-page .products-section {
    position: relative;
    padding: 45px;
    padding-bottom: 20px;
}

.search-page .products-section h3 {
    font-size: 20px;
    font-weight: 300;
}

.sub-cat{
    font-size: 20px !important;
}

.av-check{
    margin-right: 10px;
}

.alphabetical-filter {
    position: relative;
    margin-left: auto;
    margin-right: 50px;
    display: flex;
    flex-wrap: nowrap;
    width: 600px;
    height: auto;
    color: #131921;
    font-family: 'SKF';
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 0.8px;
}

.letter-button {
    flex-wrap: nowrap;
    text-align: center;
    min-width: 17px;
    height: 21px;
    cursor: pointer;
}

.active{
    font-weight: 700;
}

.letter-button#showAll {
    width: auto !important;
    padding-left: 8px;
    text-align: left;
    border-left: 1px solid #D8D8D8;

}

@media only screen and (max-width: 768px) {
    .search-page .filters-section .close{
        
    }
    .search-page .filters-section {
        display: none;
        position: fixed;
        max-width: none;
        overflow-y: auto;
        min-width: 200px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: white;
        padding: 70px;
    }
}

@media only screen and (max-width: 425px) {
    .search-page .products-section {
        padding: 10px;
    }
}