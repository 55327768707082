.order-page .buy-again {
    padding: 12px 25px;
    background-color: #232F3E;
    color: #FFFFFF;
    border: 0;
    border-radius: 3px;
    height: 38px;
    margin-top: 10px;
}

.order-page .download-nf {
    padding: 12px 25px;
    background-color: #232F3E;
    color: #FFFFFF;
    border: 0;
    border-radius: 3px;
    height: 38px;
    margin-top: 10px;
    margin-left: 10px;
}

.order-page .pay-again {
    padding: 12px 25px;
    background-color: #232F3E;
    color: #FFFFFF;
    border: 0;
    border-radius: 3px;
    height: 38px;
    margin-top: 10px;
    margin-right: 10px !important;
}

.order-page .order-num {
    font-weight: 300;
    font-size: 16px;
}

.order-page .order-details-header {
    border: 1px solid #1319213B;
    border-radius: 3px 3px 0 0;
    padding: 25px;
    position: relative;
    background-color: #F6F6F6;
}

.order-page .order-details {
    border-left: 1px solid #1319213B;
    border-right: 1px solid #1319213B;
    border-bottom: 1px solid #1319213B;
    border-radius: 0 0 3px 3px;
    padding: 25px;
}

.order-page .order-details-header .order-detail-section .title {
    font-size: 16px;
    font-weight: 700;
    color: #414141;
}

.order-page .order-details-header .order-detail-section .info {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #414141;
}

.order-page .order-details-header .order-detail-section .info .bold-total {
    font-weight: 700;
    top: 0px !important;
}

.order-page .tab-wrapper {
    padding: 0 20px 1px;
    border-bottom: 1px solid #142F4D;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none !important;
}

.order-page .tab-wrapper span{
    position: static;
    margin-left: 10px;
    margin-bottom: 10px;
}

.order-page .tab-wrapper::-webkit-scrollbar, .order-page .tab-wrapper::-webkit-scrollbar-button, .order-page .tab-wrapper ::-webkit-scrollbar-thumb, .order-page .tab-wrapper::-webkit-scrollbar-track, .order-page .tab-wrapper::-webkit-scrollbar-track-piece, .order-page .tab-wrapper::-webkit-scrollbar-corner, .order-page .tab-wrapper::-webkit-resizer {
    display: none;
}

.order-page .tab {
    margin-right: 30px;
    cursor: pointer;
}

.order-page .tab p {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px;
    color: #414141A1;
    white-space: nowrap;
}

.order-page .active {
    border-bottom: 3px solid #142F4D;
    color: #414141 !important;
}

@media only screen and (max-width: 817px) {
    .order-page .order-details-header .order-detail-section {
        width: 50%;
    }
    .order-page .order-details-header .order-detail-section:first-of-type {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .order-page .order-details-header .order-detail-section {
        width: 100%;
        margin-bottom: 20px;
    }
}