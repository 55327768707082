.survey-page{
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

.survey-page .surver-loader-wrapper{
    position: relative;
    margin: 50px;
}

num-10:hover {
    background-color: #2E7F18;
    color: white !important;
}

#num-9:hover {
    background-color: #2E7F18;
    color: white !important;
}

#num-8:hover {
    background-color: #45731E;
    color: white !important;
}

#num-7:hover {
    background-color: #45731E;
    color: white !important;
}

#num-6:hover {
    background-color: #675E24;
    color: white !important;
}

#num-5:hover {
    background-color: #675E24;
    color: white !important;
}

#num-4:hover {
    background-color: #8D472B;
    color: white !important;
}

#num-3:hover {
    background-color: #8D472B;
    color: white !important;
}

#num-2:hover {
    background-color: #B13433;
    color: white !important;
}

#num-1:hover {
    background-color: #B13433;
    color: white !important;
}

#num-0:hover {
    background-color: #C82538;
    color: white !important;
}

.num-10-active {
    background-color: #2E7F18;
    color: white !important;
}

.num-9-active {
    background-color: #2E7F18;
    color: white !important;
}

.num-8-active {
    background-color: #45731E;
    color: white !important;
}

.num-7-active {
    background-color: #45731E;
    color: white !important;
}

.num-6-active {
    background-color: #675E24;
    color: white !important;
}

.num-5-active {
    background-color: #675E24;
    color: white !important;
}

.num-4-active {
    background-color: #8D472B;
    color: white !important;
}

.num-3-active {
    background-color: #8D472B;
    color: white !important;
}

.num-2-active {
    background-color: #B13433;
    color: white !important;
}

.num-1-active {
    background-color: #B13433;
    color: white !important;
}

.num-0-active {
    background-color: #C82538;
    color: white !important;
}

#outlook a {
    padding: 0;
}

.ReadMsgBody {
    width: 100%;
}

m .ExternalClass {
    width: 100%;
}

.ExternalClass * {
    line-height: 100%;
}

.enviar {
    margin: 10px 0 2px 0;
    display: flex;
    justify-content: center;
}

.enviar-button {
    cursor: pointer;
    text-align: center;
    width: 200px;
    border-radius: 4px;
    background-color: #121921;
    color: white;
    padding: 8px 0;
}

body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

table,
td {
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}

img {
    border: 0;
    height: auto;
    line-height: 100%;
    outline: none;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
}

h1 {
    margin-top: 0;
    margin-bottom: 14;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.1em;
}

p,
h2,
h3,
h4 {
    margin-top: 0;
    margin-bottom: 12;
    padding-top: 0;
    padding-bottom: 0;
}

a {
    cursor: pointer;
}

ul,
ol {
    margin-top: 14;
    margin-bottom: 14;
    font-weight: normal;
}

ul,
ol,
li {
    margin-left: 20;
    margin-right: 0;
    padding-left: 20;
    padding-right: 0;
}

ul,
ol {
    margin-left: 20px;
}

ol,
ol,
li {
    list-style-type: disc;
}

.arrow_box {
    margin-top: -20px;
    position: relative;
    background: #ffffff;
    border-top: 0px solid #ffffff;
}

.arrow_box:after,
.arrow_box:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    margin-top: -1px;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 13px 20px 0 20px;
    margin-left: -20px;
}

.arrow_box:before {
    border-color: rgba(232, 232, 232, 0);
    border-top-color: #e8e8e8;
    border-width: 13.5px 20.5px 0 20.5px;
    margin-left: -20.3px;
}

a[href^="x-apple-data-detectors:"] {
    color: inherit;
    text-decoration: inherit;
}

@media only screen and (max-width: 480px) {
    @-ms-viewport {
        width: 320px;
    }

    @viewport {
        width: 320px;
    }

    .mobile-full-width {
        width: 100% !important;
    }

    .mobile-hide {
        display: none !important;
    }

    .mobile-padding {
        padding-bottom: 30px;
    }
}

@media only screen and (min-width: 480px) {
    .mj-column-per-100 {
        width: 100% !important;
    }

    .mj-column-per-48 {
        width: 48.5% !important;
    }

    .mj-column-per-3 {
        width: 3% !important;
    }

    .mj-column-per-80 {
        width: 80% !important;
    }

    .mj-column-per-19 {
        width: 19% !important;
    }
}