
.order-listing-mobile {
    border: 1px solid #C6C6C6;
    border-radius: 0 0 4px 4px;
    padding: 10px 15px;
    align-items: center;
}

.order-listing-mobile p{
    font-size: 16px;
}

.order-listing-mobile img {
    max-width: 50px;
    max-height: 50px;
    margin-right: 20px;
}

.order-listing-mobile .arrow{
    filter: invert(100%);
    max-height: 15px;
    margin-right: 0;
}

@media only screen and (max-width:375px){
    .order-listing-mobile p{
        font-size: 14px;
    }
}