.product-box {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    max-width: 220px;
    min-width: 200px;
    width: 25vw;
    height: 99%;
    max-height: 240px;
    cursor: pointer;
    justify-content: space-between;
}

.product-box .image-wrapper{
    display: flex;
    width: 100%;
    height: 50%;
    padding:0;
}

.product-box img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.product-box .info-wrapper {
    padding: 0 20px;
    font-size: 15px;
    font-weight: 500;
    color: #131921;
}

.product-box .info-wrapper .limiter {
    max-height: 62px;
    padding-top: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    font-weight: 500;
    font-size: 16px;
    color: #131921;
}

.product-box .price {
    font-weight: 500;
    font-size: 16px;
}