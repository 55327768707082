.carousel-wrapper {
    position: relative;
    background-color: white;
    border-radius: 4px;
    height: 330px;
    flex: 1;
    min-width: 0;
    padding: 25px 30px;
}

.carousel-wrapper .prev, .carousel-wrapper .next {
    background-color: #131921;
    width: 45px;
    height: 45px;
    top: 45%;
    text-align: center;
    border-radius: 2px;
}

.carousel-wrapper .prev img, .carousel-wrapper .next img {
    display: block;
    height: 15px;
    margin: auto;
}

.carousel-wrapper .scroller {
    width: 100%;
    height: 80%;
    overflow: hidden;
    padding: 0 40px;
}

.flex-carousel {
    flex-wrap: nowrap !important;
    justify-content: start;
    overflow: auto;
    overflow-x:hidden;
    height: 100%;
}