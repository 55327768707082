.notifications-page-wrapper {}

.breadcrumbs h3 {
    font-weight: 300;
    font-size: 24px;
    margin-right: 1%;

}

.breadcrumbs h4 {
    font-weight: 300;
    font-size: 22px;
    margin-right: 1%;

}

.breadcrumbs p {
    font-weight: 700;
    font-size: 22px;
    margin-left: 1%;
}

.notifications-page-wrapper .breadcrumbs {
    color: #414141;
    font-size: 16px;
    font-weight: 300;
}

.notifications-page-wrapper .main-section {
    width: 100% !important;
    margin-right: 20px;
}

.notifications-page-wrapper .main-section  .light-text{
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
}

.notifications-page-wrapper .main-section .loader-wrapper {
    position: relative;
    width: 90%;
    margin: auto;
    height: 90px;
}

.notifications-page-wrapper .main-section p {
    font-size: 18px;
    color: #414141;
    font-weight: 600;
}

.notifications-page-wrapper .user-wrapper {
    padding: 25px 0px;
}


.notifications-page-wrapper .main-section .info-section-wrapper .info-section {
    padding: 45px;
    border: 1px solid RGB(54, 71, 92, 0.25);
    border-radius: 4px;
}

.notifications-page-wrapper .main-section .info-section-wrapper .info-section .checkbox-wrapper {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 21px;
}

.notifications-page-wrapper .main-section .info-section-wrapper .info-section .checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 4px !important;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #DBDBDB;
    border-radius: 3px;
}

.notifications-page-wrapper .main-section .info-section-wrapper .info-section .checkbox-wrapper p {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
}

.notifications-page-wrapper .main-section .info-section-wrapper .info-section .checkbox-wrapper a {
    color: #303030 !important;
}

.checkbox-wrapper input:checked~.checkmark {
    background-color: #171717;
}

.checkbox-wrapper input:disabled~.checkmark {
    background-color: #DBDBDB;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-wrapper input:checked~.checkmark:after {
    display: block;
}

.checkmark:after {
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width: 768px) {

    .notifications-page-wrapper .main-section .info-section-wrapper .info-section {
        padding: 20px;
    }

    .notifications-page-wrapper .main-section .info-section-wrapper .info-section .checkbox-wrapper {
        height: auto !important;
    }

}