.loader-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: white;
}

.policy-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.58);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    display: flex;
    flex-flow: column;
    background-color: #fefefe;
    margin: auto;
    padding: 50px;
    border: 7px solid #131921;
    width: 80%;
    max-width: 900px;
    height: 80%;
}

.policy-modal .modal-content h3 {
    color: #131921;
    text-align: center;
}

.policy-modal .modal-content .policy-text {
    overflow: auto;
    flex: 1;
    padding: 10px;
}

.policy-modal .modal-content .error-message{
    min-height: 35px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.policy-modal .modal-content .action-button{
    width: 100%;
    max-width: 335px;
    min-width: 200px;
    margin: 5px 10px;
    background-color:#232F3E;
}

.policy-modal .modal-content .loader-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

@media only screen and (max-width: 900px) {
    .modal-content {
        padding: 20px 15px;
        width: 90%;
        height: 90%;
    }
    .policy-modal .modal-content .error-message{
        margin-top: 10px;
        min-height: 0px;
    }
}