.nav-section a {
    color: #FFFFFF;
    text-decoration: none;
}

.navbar-main {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 100%;
    padding: 15px 50px;
    background-color: #131921;
    color: #FFFFFF;
    z-index: 99;
}

.navbar-main .dropdown-content .wrapper {
    background-color: #FFFFFF;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.navbar-main .dropdown-content {
    /* display: none; */
    position: absolute;
    background-color: transparent;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
}

.dropdown-secondary {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #232F3E;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
    background-color: #131921;
}

.dropdown-content-secondary {
    display: none;
    position: absolute;
    background-color: #232F3E;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right:10px;
    z-index: 1;
}

.dropdown-content-secondary a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content-secondary a:hover {
    background-color: #131921;
}

.dropdown-secondary:hover .dropdown-content-secondary {
    display: block;
}

.navbar-main .company-entry {
    padding: 10px;
    background-color: #1E2B3C;
    color: white;
    font-weight: 300;
    cursor: pointer;
}

.nav-section .user-holder{
    padding: 12px;
    border-radius: 8px;
    background-color: white;
    color: #000000;
    position: relative;
    cursor: pointer;
}

.nav-section .user-content-holder{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.nav-section .user-content-holder svg{
    margin-left: 5px;
}

.icon-wrapper {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 25px;
}

.icon-wrapper>* {
    margin: auto;
    margin-right: 15px;
}

.nav-text {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.nav-icon {
    height: 28px;
    margin: auto;
    margin-bottom: 6px;
}

.navbar-main form {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-shrink: 1;
    border-radius: 9px;
    justify-content: space-around;
    width: 100%;
}

.navbar-main .navbar-brand {
    margin: auto;
    margin-right: 35px !important;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
}

.navbar-main form input {
    box-sizing: border-box !important;
    margin: auto;
    width: 100% !important;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    outline: none;
    padding: 0 15px;
    height: 48px;
    font-size: 19px;
    line-height: 19px !important;
}

.navbar-main form input[type=submit]{
    flex: 0;
}

.navbar-main .input-holder{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 1;
    /* width: 100% !important; */
    position: relative;
    box-sizing: border-box !important;
}

.nav-section .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh !important;
    z-index: 50;
    background-color: #0000006c;
}

.navbar-main .input-holder .predictions-holder{
    box-sizing: border-box !important;
    max-height: 60vh;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 0 0 9px 9px;
    position: absolute;
    top: 60px;
    background-color: #FFFFFF;
    color: #131921;
    z-index: 100;
    padding-inline-start: 0 !important;
}

.navbar-main .input-holder .predictions-holder button,
.navbar-main .input-holder .predictions-holder p{
    box-sizing: border-box !important;
    cursor: pointer;
    height: auto !important;
    font-weight: 200 !important;
    padding: 7px 15px;
    width: 100% !important;
    background-color: #FFFFFF;
    color: #131921;
    border: 0;
    border-radius: 0;
    text-align: left;
    font-size: 19px;
}

.navbar-main .input-holder ul {
    list-style-type: none;
}

.navbar-main .input-holder .predictions-holder button:hover{
    background-color: aliceblue;
}

.navbar-main .input-holder .predictions-holder .loader-wrapper{
    position: relative;
    width: 100%;
    height: 100px;
}


input::placeholder {
    color: rgb(182, 182, 182);
    font-weight: 300;
}

.navbar-main form .button,
.navbar-main form button {
    cursor: pointer;
    width: 128px !important;
    margin: auto;
    background-color: #142F4D;
    color: #FFFFFF;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 48px;
    font-size: 19px;
}

.navbar-main form .button, 
.navbar-main form button, 
.navbar-main form input {
    box-sizing: content-box;
    border: 3px solid #142F4D;
}

.nav-section form:focus-within {
    outline: none;
    border: 2px solid rgba(59, 59, 187, 1) !important;
    border-radius: 9px;
}

#nav-username {
    margin-right: 30px;
}

.navbar-secondary {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    background-color: #FFFFFF;
    padding: 14px 35px;
    box-shadow: 0 2px 4px 0 #00000042;
    
}

.navbar-secondary .scroller {
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
    scrollbar-width: none !important;
}

.navbar-secondary .navbar-input{
    padding: 0px 10px;
    border-radius: 4px;
    border: 1px solid rgb(196, 195, 195);
    padding: 5px;
    /* border: none; */
    font-size: 16px;
    background-color: rgb(249 249 249);
    /* background-color: rgb(243, 243, 243);     */
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'SKFNOOT';
    font-weight: 300;
}

.navbar-secondary .navbar-submit{
    background-color: #131921 !important;
    color: #FFFFFF !important;
    border: none;
    cursor: pointer;
}

.navbar-secondary .reverse{
    background-color: #FFFFFF !important;
    color: #131921 !important;
    cursor: pointer;
    min-width: 100px;
    width: 100px;
    border: 1px solid #131921;
}

.navbar-secondary select{
    font-size: 16px;
    font-weight: 400 !important;
    width: 150px !important;
    min-width: 100px !important;
    font-family: 'SKFNOOT';
    font-weight: 300 !important;
}

.navbar-secondary input{
    width: 260px;
    min-width: 180px;
    font-family: 'SKF' !important;
}

.navbar-secondary .placeholder{
    color:rgb(196, 195, 195) !important;
}

.navbar-secondary .scroller::-webkit-scrollbar, .navbar-secondary .scroller::-webkit-scrollbar-button, .navbar-secondary .scroller::-webkit-scrollbar-thumb, .navbar-secondary .scroller::-webkit-scrollbar-track, .navbar-secondary .scroller::-webkit-scrollbar-track-piece, .navbar-secondary .scroller::-webkit-scrollbar-corner, .navbar-secondary .scroller::-webkit-resizer {
    display: none;
}

.navbar-secondary .scroller>* {
    margin-right: 20px;
    vertical-align: middle;
}

.navbar-secondary .hamburger-container{
    display: none;
}

.navbar-secondary p {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px !important;
    white-space: nowrap;
    color: #232F3E;
}

.categories-wrapper {
    margin-right: 60px;
    margin-left: 30px !important;
    white-space: nowrap;
}

.categories-wrapper>* {
    vertical-align: top;
}

.categories-wrapper p {
    font-weight: 700;
    line-height: 20px !important;
    text-align: center;
}



.hamburger-container {
    display: inline-block;
    cursor: pointer;
    margin-right: 17px;
}

.hamburger-bar1, .hamburger-bar2, .hamburger-bar3 {
    width: 18px;
    height: 2px;
    background-color: #232F3E;
    margin: 3px 0;
    transition: 0.4s;
}

.change .hamburger-bar1 {
    -webkit-transform: rotate(-45deg) translate(-4px, 3px);
    transform: rotate(-45deg) translate(-4px, 3px);
}

.change .hamburger-bar2 {
    opacity: 0;
}

.change .hamburger-bar3 {
    -webkit-transform: rotate(45deg) translate(-4px, -3px);
    transform: rotate(45deg) translate(-4px, -3px);
}

@media only screen and (max-width: 1250px) {
    #nav-username {
        margin-right: 15px;
    }
    .icon-wrapper{
        order: 2
    }
    .navbar-main>*, .navbar-main .navbar-brand {
        margin: auto 0;
    }
    .navbar-main {
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .navbar-main .input-holder {
        order: 100;
        flex: 0 0 100%;
        margin-top: 20px;
    }

    .navbar-secondary .navbar-input{
        width: 45%;
        margin: 5px !important;
    }

    .navbar-secondary .input-full{
        width: 91%;
        margin: auto;
    }

    .navbar-secondary{
        display: block;
    }

    .navbar-secondary .scroller{
        flex-wrap: wrap;
        justify-content: center;
    }

    .navbar-secondary .categories-wrapper{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 10px !important;
    }

    .navbar-secondary .hamburger-container{
        display: block;
    }



}

@media only screen and (max-width: 700px) {
    #nav-orders {
        display: none !important;
    }
    .navbar-main {
        justify-content: space-between;
        padding: 15px;
    }
    .navbar-main form {
        order: 100;
        width: 100%;
        margin-top: 20px;
    }
    .icon-wrapper>* {
        margin: auto 0;
    }
    #nav-location {
        margin-right: 10px;
    }
    .navbar-main .navbar-brand {
        font-size: 18px;
    }
    .navbar-main form .button,
    .navbar-main form button {
        height: 36px;
        font-size: 16px;
    }
    .navbar-main form input {
        height: 36px;
    }
    .nav-text {
        font-size: 10px;
    }
    .nav-icon {
        height: 20px;
        margin-bottom: 5px;
    }
    .navbar-main .input-holder .predictions-holder{
        top: 60px;
    }
    .navbar-secondary {
        padding: 10px 15px;
        justify-content: space-between;
    }
    .navbar-secondary a {
        font-size: 12px;
    }
}

@media only screen and (max-width: 453px) {
    input::placeholder {
        font-size: 14px;
    }
    .navbar-secondary .navbar-input{
        width: 100%;
        margin: 5px !important;
    }
}