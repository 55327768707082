.div-body {
  display: flex;
  padding: 32px 60px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.banner {
  width: 100%;
  height: 447px;
  border-radius: 4px;
  background: url("../../assets/images/banner_tmg_racing.png") lightgray 50% /
    cover no-repeat;
}

.div-container {
  position: relative;
  height: 300px; /* Specify the height */
  width: 100%; /* Optional, depending on your design */
}

.div-container img {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 120px;
}

.logo-tmg {
  width: 190px;
  height: 190px;
  position: relative;
  left: calc(50% - 190px + 60px);
  bottom: -60%;
  background: url("../../assets/images/Stock_Car_BR.png") no-repeat;
}
.logo-default {
  width: 100%;       /* or any other width */
  height: auto;
  position: relative;
  left: calc(50% - 190px + 60px);
  bottom: -60%;
  background: url("../../assets/images/Stock_Car_BR.png") no-repeat;
}


.content-page {
  display: flex;
  width: 100%;
  padding: 32px 0px 120px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.25);
}
.wellcome-div {
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.wellcome-div p {
  color: #032859;
  font-family: "SKF Sans";
  font-style: normal;
  line-height: 130%;
}
.title {
  font-size: 36px;
  font-weight: 700;
}
.text {
  font-size: 20px;
  font-weight: 400;
}

.img-car {
  display: flex;
  width: 100%;
  height: 400px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
}

.products-div {
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  gap: 76px;
  align-self: stretch;
}

@media (max-width: 768px) {
  .products-div {
    flex-direction: column;
    gap: 20px; 
    justify-content: center; 
    align-items: center;
  }
}

.final-text {
  display: flex;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}

.final-text p {
  color: #5e5e5e;
  text-align: center;
  font-family: "SKF Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.final-price {
  display: flex;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}

.final-price p {
  color: #101010;
  text-align: center;
  font-family: "SKF Sans";
  font-size: 18px;
  font-style:normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}



.w-100{
  width: 100%;
}

/* Responsive image styling */
.img-car {
  width: 100%; /* Ensures the container takes the full width of its parent */
  overflow: hidden; /* Optional, depending on design needs */
}

.img-car img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

/* Example Media Query for larger screens */
@media (min-width: 768px) {
  .img-car img {
      max-width: 1000px; /* Maximum width on large screens */
      margin: 0 auto; /* Centering if the container is wider */
  }
}