/* Style for the input field */
.autocomplete-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Style for the suggestion list */
  .autocomplete-list {
    border: 1px solid #ddd;
    border-top: none;
    z-index: 9900;
    margin-top: 0px;
    /* top: 100%; */
    left: 0;
    right: 0;
    width:parent;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    margin: 0;  /* Removes default margin */
    padding: 0; /* Removes default padding */
    list-style-type: none; /* Removes default list style */
    max-height: 123px; /* Optional: Limit height of dropdown */
    overflow-y: auto; /* Optional: Add scrollbar if content overflows */
  }
  
  /* Style for each suggestion item */
  .autocomplete-item {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  /* Style for an item on hover */
  .autocomplete-item:hover {
    background-color: #f1f1f1;
  }