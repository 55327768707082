.registration-page {
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
}

.registration-page .form-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 120px;
    margin: auto;
    max-width: 1440px;
}

.registration-page .section-one {
    width: 90%;
    height: 100%;
    background-color: #131921;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.order-one {
    order: 1;
    margin-right: 50px;
}

.order-two {
    order: 2;
    margin-left: 50px;
}

.registration-page .section-two {
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.registration-page .section-two h3 {
    margin-bottom: 60px;
}

.registration-page .section-two .description {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 5px;
}

.registration-page .form-container h1 {
    color: #FFFFFF;
    text-align: center;
    font-size: 60px;
}

.registration-page .form-container form {
    max-width: 820px;
    margin: auto;
    padding: 25px 25px 10px 25px;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.registration-page .form-container form .error {
    display: none;
    font-size: 14px;
    color: #81263b;
    margin-top: 5px;
}

.registration-page .form-container .red{
    color: #81263b;
    font-weight: 700;
}

.registration-page .form-container form input[type='checkbox'] {
    width: auto !important;
    margin-right: 10px;
}


.registration-page .form-container .success-message {
    color: #1C8870;
}

.registration-page .form-container form .loader-wrapper {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
}

.registration-page .form-container .section-two .label {
    font-size: 14px;
    line-height: 18px;
    color: #131921 !important;
    margin-bottom: 5px;
    padding-left: 0;
    white-space: normal;
    text-align: left;
}

.registration-page .form-container form .input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #979797;
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    padding: 10px;
    margin-bottom: 5px;
}


.registration-page .form-container form:focus-within {
    border: 0 !important;
}

.registration-page .form-container form input[type=submit] {
    background-color: #232F3E;
    margin-top: 15px;
    height: 60px;
    color: white;
}

.registration-page .form-container form input[type=submit]:disabled {
    background-color: #8b99aa;
    margin-top: 15px;
    height: 60px;
    color: white;
}

.registration-page .form-container form .disclaimer {
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #303030;
}

.registration-page .form-container form h5 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: #232F3E;
}

.registration-page .form-container form p {
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #232F3E;
}

.registration-page .form-container form .error {
    font-size: 16px;
    color: #81263b;
}

.registration-page .terms-conditions {
    font-size: 18px !important;
}

.registration-page .description {
    font-size: 18px !important;
    margin-bottom: 20px;
}

.registration-page .mt-15 {
    margin-top: 15px;
}

.registration-page .hidden {
    display: none;
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.875em !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
    top: 2.375em !important;
}

.registration-page .form-container form .disabled {
    background-color: #797979 !important;
    color: white !important;
}

.registration-page .form-container .section-two .light-grey {
    color: #797979 !important;
}

.registration-page .form-container form .error-input {
    border: 1px solid #81263b !important;
}

.registration-page .rotator-wrapper {
    height: 200px;
}

.registration-page .pass-holder {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.registration-page .mask-password,
.registration-page .mask-password:focus {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    border: 0 !important;
    outline: none;
}

.registration-page .action-button{
    border-radius: 8px !important;
    background-color: #289067 !important;
    color: white;
}

.registration-page .form-container form .disabled{
    background-color: #797979 !important;
    color: white !important;
}

@media only screen and (max-width: 900px) {
    .registration-page .form-container {
        flex-flow: column nowrap;
        padding: 0;
    }

    .registration-page .section-one {
        width: 100%;
        height: 30%;
        margin-left: 0;
        order: 1 !important
    }

    .registration-page .section-two {
        order: 2 !important;
        height: 70%;
    }

    .order-one {
        margin-right: 0px;
    }

    .order-two {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 450px) {
    .registration-page .form-container h1 {
        font-size: 40px;
    }
}