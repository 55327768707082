.order-line {

}

.order-line .details-box-1 {
    width: 50%;
}

.order-line .details-box-2 {
    width: 50%;
}

.order-line .details-box-1 .title, .order-line .details-box-2 .title, .order-line .title{
    font-weight: 700;
    font-size: 15px;
    color: #414141;
    margin-bottom: 8px;
}

.order-line .details-box-1 .title span, .order-line .details-box-2 .title span {
    font-weight: 300;
    font-size: 14px;
    color: #414141;
    top: 0 !important;
}

.order-line .details-box-1 .title a, .order-line .details-box-2 .title a {
    font-weight: 300;
    font-size: 14px;
    color: #414141;
    top: 0px !important;
}

.order-line .details-box-1 .title a, .order-line .details-box-2 .title .entrega {
    font-weight: 500;
    border-bottom: 1px solid #414141;
    text-decoration: none;
}

.order-line .details-box-1 .title .nota-fiscal, .order-line .details-box-2 .title .nota-fiscal {
    font-size: 12px;
}

.order-line .details-box-1 .wrap, .order-line .details-box-2 .wrap{
    word-break: break-all;
}

.order-line .details-box-1 .no-wrap, .order-line .details-box-2 .no-wrap{
    white-space: pre;
}


.order-line .warning {
    align-items: center;
    border-radius: 4px;
    border: 1px solid #fdf7df;
    background-color: rgba(254, 236, 111, 0.4);
    display: flex;
    gap: 10px;
    padding: 6px 10px;
    margin-bottom:10px;
  }
  .order-line .warning-img {
    aspect-ratio: 1.38;
    object-fit: contain;
    object-position: center;
    width: 18px;
    background-blend-mode: hard-light;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
  }

  .order-line .warning-2 span {
    display: block;  /* or use flexbox layout as per your design needs */
}
  .order-line .warning-2 {
    color: #5f4c00;
    letter-spacing: 0.22px;
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    font: 500 14px SKF Chevin, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
@media only screen and (max-width: 600px) {
    .order-line .details-box-1, .order-line .details-box-2{
        width: 100%;
    }
}
