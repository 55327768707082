.flex-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.flex-wrapper .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.flex-wrapper .loader-bold div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid #232F3E;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.flex-wrapper .loader-light div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 2px solid #232F3E;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.flex-wrapper .loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.flex-wrapper .loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.flex-wrapper .loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}