.mt--300{
    margin-top: -600px !important;
}
.star-style{
    fill:#FFCC00;
    stroke:#FF9900;
    stroke-width:3;
    fill-rule:evenodd;
}

.star-style-1{
    fill:yellow;stroke:black;stroke-width:1
}

.div-4 {
    justify-content: center;
    border-radius: 16px 16px 2px 2px;
    background-color: #232f3e;
    align-self: center;
    display: flex;
    width: 100%;
    max-width: 1440px;
    flex-direction: column;
    padding: 32px 64px 40px;
    z-index: 1000000000000000000 !important;
  }

  .div-4 a{
    text-decoration: none;
  }
  

  .div-8 a{
    text-decoration: none;
  }

  .div-7 a{
    text-decoration: none;
  }

  .div-5 a{
    text-decoration: none;
  }

  @media (max-width: 991px) {
    .div-4 {
        max-width: 100%;
        padding: 0 20px;
        padding-bottom: 30px;
    }
  }
  .div-5 {
    color: #fff;
    font: 400 32px/115.6% SKF Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-5 {
      max-width: 100%;
      margin-top:20px;
    }
  }
  .div-6 {
    justify-content: center;
    margin-top: 24px;
  }
  @media (max-width: 991px) {
    .div-6 {
      max-width: 100%;
    }
  }
  .div-7 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-7 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 25%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column {
      width: 100%;
    }
  }
  .div-8 {
    border-radius: 2px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    padding-bottom: 16px;
    flex-direction: column;
    font-size: 24px;
    color: #0f58d6;
    font-weight: 400;
    line-height: 130%;
    width: 100%;
  }
  @media (max-width: 991px) {
    .div-8 {
      margin-top: 40px;
    }
  }
  .img-3 {
    aspect-ratio: 2.94;
    object-fit: auto;
    object-position: center;
    width: 100%;
    align-self: center;
  }
  .div-9 {
    font-family: SKF Sans, sans-serif;
    margin: 26px 16px 0;
  }
  @media (max-width: 991px) {
    .div-9 {
      margin: 0 10px;
    }
  }
  .column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 25%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-2 {
      width: 100%;
      margin-left:0px;
    }
  }
  .div-10 {
    border-radius: 2px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    padding-bottom: 16px;
    flex-direction: column;
    font-size: 24px;
    color: #0f58d6;
    font-weight: 400;
    white-space: nowrap;
    line-height: 130%;
    width: 100%;
  }
  @media (max-width: 991px) {
    .div-10 {
      margin-top: 40px;
      white-space: initial;
    }
  }
  .img-4 {
    aspect-ratio: 2.94;
    object-fit: auto;
    object-position: center;
    width: 100%;
    align-self: center;
  }
  .div-11 {
    font-family: SKF Sans, sans-serif;
    margin: 26px 16px 0;
  }
  @media (max-width: 991px) {
    .div-11 {
      margin: 0 10px;
    }
  }
  .column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 25%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-3 {
      width: 100%;
      margin-left:0px;
    }
  }
  .div-12 {
    border-radius: 2px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    padding-bottom: 16px;
    flex-direction: column;
    font-size: 24px;
    color: #0f58d6;
    font-weight: 400;
    line-height: 130%;
    width: 100%;
  }
  @media (max-width: 991px) {
    .div-12 {
      margin-top: 40px;
    }
  }
  .img-5 {
    aspect-ratio: 2.94;
    object-fit: auto;
    object-position: center;
    width: 100%;
    align-self: center;
  }
  .div-13 {
    font-family: SKF Sans, sans-serif;
    margin: 26px 16px 0;
  }
  @media (max-width: 991px) {
    .div-13 {
      margin: 0 10px;
    }
  }
  .column-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 25%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-4 {
      width: 100%;
      margin-left:0px;
    }
  }
  .div-14 {
    border-radius: 2px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    padding-bottom: 16px;
    flex-direction: column;
    font-size: 24px;
    color: #0f58d6;
    font-weight: 400;
    line-height: 130%;
    width: 100%;
  }
  @media (max-width: 991px) {
    .div-14 {
      margin-top: 40px;
    }
  }
  .img-6 {
    aspect-ratio: 2.94;
    object-fit: auto;
    object-position: center;
    width: 100%;
    align-self: center;
  }
  .div-15 {
    font-family: SKF Sans, sans-serif;
    margin: 26px 16px 0;
  }
  @media (max-width: 991px) {
    .div-15 {
      margin: 0 10px;
    }
  }