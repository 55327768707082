.footer-position {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.back-to-top {

    background-color: #232F3E;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1% 0%;
    cursor: pointer;
}

.footer-arrow {
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: fixed;
    right: 5%;
    bottom: 2%;
    background-color: #232F3E;
}

.footer-wrapper {
    width: 100%;
    padding: 60px 150px 40px;
    background-color: #131921;
    justify-content: space-between;
    flex-wrap: nowrap;
}

@media (max-width: 1024px) {
    .footer-arrow {
        height: 40px;
        width: 40px;
        right: 5%;
        bottom: 5%;
    }

    .footer-wrapper {
        width: 100%;
        padding: 60px 50px;
        background-color: #131921;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}


.footer-buffer {
    height: 300px;
}

.footer-col {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    min-width: 180px;
    width: 20vw;
}

.footer-social {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    min-width: 180px;
    width: 20vw;
}

.footer-col h3, .footer-social h3 {
    color: #E4E4E4;
    font-size: 17px !important;
    font-weight: 600;
    margin-bottom: 14px;
}

.footer-col a, .footer-social a {
    color: #F5F5F5;
    font-size: 16px !important;
    font-weight: 200;
    margin-bottom: 14px;
    text-decoration: none;
}

.footer-col a:hover {
    text-decoration: underline;
}

.footer-col p {
    color: #FFFFFF;
}

.social {
    height: 30px;
    margin-right: 25px;
}

.footer-logo {
    width: 100%;
    text-align: center;
    background-color: #131921;
    padding: 14px;
    border-top: solid 2px #232F3E;
}

.footer-logo a {
    color: white;
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    text-decoration: none;
}

.lisa-text {
    font-size: 18px;
    font-weight: 200;
    vertical-align:middle;
    top: 0px !important
}

@media only screen and (max-width: 1250px) {
    .social {
        height: 20px;
        margin-right: 15px;
    }
}