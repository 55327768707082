.account-card {
    width: 32%;
    height: 140px;
    border-radius: 8px;
    border: solid 1px #131921;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    margin-right: 0%;
    text-decoration: none;
}

.account-card .card-image{
    display: flex;
    justify-content: center;
    align-items: start;
    width: 30%;
    margin: 0% 3%;
}

.account-card .card-image .image{
    width: 71px;
    height: 71px;
}

.account-card .card-body{
    padding: 0% 3%;
}

.account-card .card-body .card-title{
    font-weight: 600;
    margin-bottom: 4%;
    color: #414141;
    font-size: 1.2rem;
}

.account-card .card-body .card-content{
    color: #414141;
    font-size: 1.1rem;
    font-weight: 200;
}


@media (max-width: 768px) {

    .account-card {
        width: 100%;    
    }
}
@media (min-width: 768px) and (max-width: 1200px) {

    .account-card {
        width: 48%;    
    }
}