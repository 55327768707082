.lot-purchase-wrapper {
    padding: 50px;
}

.lot-purchase-wrapper .small-p {
    font-size: 18px;
    font-weight: 300;
    color: #303030;
}

.lot-purchase-wrapper .download img {
    width: 45px;
    height: 45px;
    margin-right: 10px;
}

.lot-purchase-wrapper .download .action-button {
    background-color: #131921;
    font-size: 14px;
    font-weight: 600;
    width: 160px;
    height: 38px;
}

.lot-purchase-wrapper .light{
    background-color: #555e68 !important;
}



.lot-purchase-wrapper .error-table h4,
.lot-purchase-wrapper .error-table p {
    color: #AE2D2D;
}

.disable{
    background-color: #b5b5b5;
}

#drop_zone {
    position: relative;
    border: 2px dashed #131921;
    width: 100%;
    padding: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.lot-purchase-wrapper .drop-zone {
    background-color: #F6F6F6;
}

.lot-purchase-wrapper .ml-28{
    margin-left: 28px;
}

#drop_zone .paragraph {
    font-size: 24px;
    font-weight: 700;
    width: 375px;
    text-align: center;
}

.lot-purchase-wrapper #drop_zone input[type="file"] {
    display: none;
}

.seguranca-content .action-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #1C2C41;
    font-size: 14px;
    width: 155px;
    height: 44px;
    margin: 3% 0;
}

.lot-purchase-wrapper #drop_zone h3 {
    font-size: 36px;
    font-weight: 700;
    color: #0D6C1F;
    text-align: center;
}

.upload-arrow {
    background-color: #131921;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.drop-zone:hover {
    background-color: #C4CDD7;
    border: 1px solid #C4CDD7;
}

.compras-em-lote h3 {
    font-size: 24px;
    font-weight: 700;
    color: #414141;
}

.lot-purchase-wrapper .after-submit {
    background-color: #FFFFFF !important;
    border: 0px #FFFFFF !important;
}

.lot-purchase-wrapper .after-submit .received {
    background-color: #2C8F67;
    padding: 2%;
    color: white;
    font-size: 24px;
    border-radius: 8px;
    margin-top: 2%;

}

.lot-purchase-wrapper .after-submit .revisamos {
    margin: 2% 0;
}

.lot-purchase-wrapper .after-submit .main-content {
    display: flex;
    flex-direction: row;
}

.lot-purchase-wrapper .after-submit .table-container {
    width: 78%;
}

.lot-purchase-wrapper .after-submit .action-container {
    margin-left: 24px;
    width: 22%;
    height: 200px;
    /* padding: 24px; */
}

.lot-purchase-wrapper .after-submit .action-container .content{
    border: 1px solid #ececec;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height: 200px;
    padding: 24px;
}

.lot-purchase-wrapper .after-submit .action-container .content p{
    font-size: 16px;
}

.lot-purchase-wrapper .after-submit .action-container .header{
    padding: 22px;
    width: 100%;
    background-color: #F6F6F6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border:1px solid #D8D8D8;
    font-size: 20px;
    font-weight: 700;
    color: #151E26;
}



.lot-purchase-wrapper .after-submit .results-header{
    padding: 22px;
    width: 100%;
    background-color: #F6F6F6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border:1px solid #D8D8D8;
    font-size: 20px;
    font-weight: 700;
    color: #151E26;
}
.lot-purchase-wrapper .after-submit .table {
    border: 1px solid #ececec;
    border-top: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 1%;
    min-width: 450px;
}

.lot-purchase-wrapper .after-submit .table .svg-box {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: #F7F7F7;
    margin-left: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: -4px;
}

.lot-purchase-wrapper .after-submit .table .table-head,
.lot-purchase-wrapper .after-submit .table .table-row,
.lot-purchase-wrapper .after-submit .table .table-footer {
    display: flex;
    flex-direction: row;
    color: #414141;
    gap: 2%;

}

.lot-purchase-wrapper .border-right{
    border-right: #ececec solid 1px;
}

.lot-purchase-wrapper .border-bottom{
    padding-bottom: 10px;
    border-bottom: #ececec solid 1px;
}

.lot-purchase-wrapper .pb-2{
    padding-bottom: 14px;
}
.lot-purchase-wrapper .pt-2{
    padding-top: 14px;
}

.lot-purchase-wrapper .after-submit .table .table-row{
    border-bottom: #ececec solid 1px;
    padding: 20px;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 0;
    align-items: center;
    min-height: 130px;
}

.lot-purchase-wrapper .after-submit .table .table-row .edit-item{
    color: #023373;
    border-right: #ececec solid 1px;
    padding-right: 15px;
}

.lot-purchase-wrapper .after-submit .delete-item{
    color: #8F2C2C
}

.lot-purchase-wrapper .after-submit .table .table-head {
    font-size: 18px;
    font-weight: 400;
}

.lot-purchase-wrapper .after-submit .table .table-row {
    margin: 0 0;
}

.lot-purchase-wrapper .after-submit .table .table-footer {
    padding-top: 1%;
    margin-top: 1px;
    justify-content: flex-end
}

.lot-purchase-wrapper .after-submit .table .table-footer .contains {
    font-size: 12px;
    font-weight: 700;
    margin-right: 2%;
}

.lot-purchase-wrapper .after-submit .table .selection-box {
    width: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;

}
.lot-purchase-wrapper .after-submit .table .code {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.lot-purchase-wrapper .after-submit .table .code .code-text {
    color: #414141;
    font-size: 22px;
    font-weight: 700;
}

.lot-purchase-wrapper .after-submit .table .quantity {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.lot-purchase-wrapper .after-submit .table .status-row {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.lot-purchase-wrapper .after-submit .table .status-cell{
    padding: 8px;
    border-radius: 5px;
    color: white;
}

.lot-purchase-wrapper .after-submit .table .editing-btn p{
    font-size: 16px;
}

.lot-purchase-wrapper .after-submit .table  .stock-unavailable{
    display: flex;
    flex-direction: row;
}

.lot-purchase-wrapper .after-submit .table  .stock-unavailable .status-cell{
    margin-left: 20px;
    height: 22px;
    font-size: 14px;
    padding: 5px;
}

.lot-purchase-wrapper .success{
    background-color: #289067;
}

.lot-purchase-wrapper .warning{
    background-color: #BA983C;
}

.lot-purchase-wrapper .danger{
    background-color: #8F2C2C;
}

.lot-purchase-wrapper .neutral{
    background-color: #919296;
}

.lot-purchase-wrapper .tooltip .tooltiptext {
    visibility: hidden;
    /* visibility: visible; */
    position: absolute;
    left: 280%;
    height: auto;
    z-index: 1000;
    width: 240px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    margin-top: -40px;
    color: #131921;
    font-weight: 200;
    font-size: 14px;
    text-align: left;
}


.lot-purchase-wrapper .tooltip:hover .tooltiptext {
    visibility: visible;
}

.lot-purchase-wrapper .tooltiptext{
    margin-right: 10px;
    
}

.lot-purchase-wrapper .after-submit .table .status-div {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;


}

.lot-purchase-wrapper .after-submit .table .status-div p{
    font-weight: 200;
    text-align: left;
}
.lot-purchase-wrapper .after-submit .delivery-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    justify-content: start;
}

.margin-left-5{
    margin-left: 5px;
}

.lot-purchase-wrapper .after-submit .table .status-special {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.lot-purchase-wrapper .after-submit .table .status {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.lot-purchase-wrapper .after-submit .table .editing-remove {
    cursor: pointer;
}


.lot-purchase-wrapper .after-submit .pagination {
    padding: 0 2%;
    margin: 2% 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 3%;
}

.lot-purchase-wrapper .send {
    margin: 2% 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lot-purchase-wrapper .send .buttons {
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.lot-purchase-wrapper .send .action-button {
    height: 55px;
    width: 210px;
    font-size: 18px;
}

.lot-purchase-wrapper .buttons .cancelar {
    /* height: 55px;
    width: 210px;
    font-size: 18px; */
    color: #1D1B1B;
    border: #DDDDDD solid 1px;
    background-color: transparent !important;
}

.lot-purchase-wrapper .send .cotar {
    background-color: #F4F4F4;
    color: #414141;
}

.lot-purchase-wrapper .send .cancelar {
    background-color: #8F2C2C;
}

.lot-purchase-wrapper .send .adicionar {
    background-color: #2C8F67;
    width: 300px !important;
}

.lot-purchase-wrapper .send .prosseguir {
    width: 300px !important;
}

.lot-purchase-wrapper .pagination .arrow {
    cursor: pointer;
}

.lot-purchase-wrapper .pagination .black {
    filter: invert(1) brightness(0)
}

.upside-down{
    transform: rotate(180deg);
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.ml-3{
    margin-left: 6px;
}

.replaced_item{
    text-decoration: line-through;
    text-decoration-color: #4f4f4f;
    color: #8F2C2C;
    margin-right: 5px;
    text-decoration-thickness: 2px;
    text-underline-offset: -10px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 350px;
    height: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 120px;
    top: 100px;
    left: 40px;
    margin-left: -20px;
    font-size: 11px;
}

.loader-wrapper {
    height: auto;
  }

.hidden {
    visibility: hidden;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.swal-wide {
    max-width: 600px !important;
    padding: 3% 0;
}

.code .searchInput {
    width: 136px;
    border-radius: 4px;
    border: 1px solid #C4CDD7;
    padding: 2%;

}

.lot-purchase-wrapper .send .note{
    font-size: 12px;
}
.lot-purchase-wrapper .send .note-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-top: 10px;;
}

.lot-purchase-wrapper .import-disabled{
    background-color: #EDEDED !important;
    color: #BABABA;
}

.lot-purchase-wrapper .custom-button {
    color: white; /* Text color */
    background-color: #131921; /* bg-blue-700 */
    padding: 0.625rem 1.25rem; /* py-2.5 px-5 */
    font-size: 1rem; /* text-sm */
    font-weight: 500; /* font-medium */
    border-radius: 20px; /* rounded-full */
    border: none;
    cursor: pointer;
    text-align: center;
    margin-bottom: 0.5rem; /* mb-2 */
    margin-right: 0.5rem; /* me-2 */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.lot-purchase-wrapper .custom-button:hover {
    background-color: #2b3440; /* hover:bg-blue-800 */
}

.lot-purchase-wrapper .custom-button:focus {
    outline: none;
    box-shadow: 0 0 0 4px #bfdbfe; /* focus:ring-4 focus:ring-blue-300 */
}

.skill{
    width: 100%;
    padding: 2px 15px;
    border-radius: 10px;
    background: #1C2C41; /* Old browsers */
  }
  .level{
    float: right;
    color: #ffffff;
  }

  /* TOOLTIP CUSTOMIZATION */

  /* Customizing the tooltip */
.introjs-button{
    background-color: #131921 !important;
    color:white !important;
    text-shadow: none !important;
}

@media only screen and (max-width: 768px) {
    #drop_zone .paragraph {
        width: 100%;
    }

    #drop_zone .action-button {
        font-size: 14px;
        max-width: 180px;
    }

    .lot-purchase-wrapper .after-submit .table .table-head {
        font-size: 16px;
        margin-bottom:20px;
    }

    .lot-purchase-wrapper .after-submit .table .status {
        width: 100%;
    }

    .lot-purchase-wrapper .after-submit .table .code .code-text {
        font-size: 16px;
    }

}

@media only screen and (max-width: 600px) {
    .lot-purchase-wrapper .after-submit .table-container {
        overflow: auto;
    }

    .lot-purchase-wrapper .after-submit .received {
        font-size: 20px;
    }

    .lot-purchase-wrapper .send .action-button {
        font-size: 12px;

    }
    #drop_zone .action-button {
        font-size: 12px;
        max-width: 160px;
    }
}