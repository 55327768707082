.order-pages form {
    width: 440px;
}

.order-pages form input {
    flex: 1 1 auto;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: none;
    padding: 0 15px;
    height: 42px;
    font-size: 16px;
    line-height: 19px !important;
    width: 100%;
}

.order-pages form button {
    flex: 0 0 auto;
    width: 151px !important;
    margin: auto;
    background-color: #DDDDDD;
    color: #333333;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
}

.order-pages form button, .order-pages form input {
    box-sizing: content-box;
    border: 1px solid #DDDDDD;
}

.order-pages form:focus-within {
    outline: none;
    border: 2px solid #9A9A9A;
    border-radius: 2px;
}

.order-pages .tab-wrapper {
    padding: 0 20px 1px;
    border-bottom: 1px solid #142F4D;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none !important;
}

.order-pages .tab-wrapper span{
    position: static;
    margin-left: 10px;
    margin-bottom: 10px;
}

.order-pages .tab-wrapper::-webkit-scrollbar, .order-pages .tab-wrapper::-webkit-scrollbar-button, .order-pages .tab-wrapper ::-webkit-scrollbar-thumb, .order-pages .tab-wrapper::-webkit-scrollbar-track, .order-pages .tab-wrapper::-webkit-scrollbar-track-piece, .order-pages .tab-wrapper::-webkit-scrollbar-corner, .order-pages .tab-wrapper::-webkit-resizer {
    display: none;
}

.order-pages .tab {
    margin-right: 30px;
    cursor: pointer;
}

.order-pages .tab p {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px;
    color: #414141A1;
    white-space: nowrap;
}

.order-pages .active {
    border-bottom: 3px solid #142F4D;
    color: #414141 !important;
}

.order-pages .number-year>* {
    font-size: 18px;
    font-weight: 300;
}

.order-pages .number, select {
    font-weight: 600 !important;
}

.order-pages select {
    border: 0;
    position: relative;
    top: 2px;
}

.order-pages select:active, .order-pages select:focus {
    border: 0;
    outline: 0;
}

.order-pages .input-holder .predictions-holder{
    box-sizing: border-box !important;
    max-height: 60vh;
    overflow-y: auto;
    width: 440px;
    margin: 0;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 0 0 9px 9px;
    position: absolute;
    top: 60px;
    background-color: #FFFFFF;
    color: #131921;
    z-index: 100;
    padding-inline-start: 0 !important;
}

.order-pages .input-holder .predictions-holder button,
.order-pages .input-holder .predictions-holder p{
    box-sizing: border-box !important;
    cursor: pointer;
    height: auto !important;
    font-weight: 200 !important;
    padding: 7px 15px;
    width: 100% !important;
    background-color: #FFFFFF;
    color: #131921;
    border: 0;
    border-radius: 0;
    text-align: left;
    font-size: 19px;
}

.order-pages.input-holder {
    order: 100;
    flex: 0 0 100%;
    margin-top: 20px;
}

/* .order-pages .input-holder .predictions-holder{
    top: 60px;
} */

.order-pages .input-holder ul {
    list-style-type: none;
}

.order-pages .input-holder .predictions-holder button:hover{
    background-color: aliceblue;
}

.order-pages .input-holder .predictions-holder .loader-wrapper{
    position: relative;
    width: 100%;
    height: 100px;
}

.order-pages .input-holder .predictions-holder{
    top: 258px !important;
}

.order-pages .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh !important;
    z-index: 50;
    background-color: #00000008;
}

@media only screen and (max-width: 768px) {
    .order-pages h3 {
        width: 100%;
        margin-bottom: 20px;
    }
    .order-pages form {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 580px) {
    .order-pages form button {
        width: 85px !important;
        height: 28px;
        font-size: 14px !important;
    }
    .order-pages form input {
        height: 30px;
        font-size: 14px;
    }
}