.checkout-page-wrapper {
    padding: 0 40px;
    margin-bottom: 200px;
}

.checkout-page-wrapper .checkout-cart-wrapper{
    min-height: 100px;
    overflow-x: auto;
}

.checkout-page-wrapper .user-wrapper, .checkout-page-wrapper .checkout-cart-wrapper, .checkout-page-wrapper .checkout-purchase-box {
    border: 1px solid #C6C6C6;
    border-radius: 4px;
}

.checkout-img{
    max-width: 75px;
    max-height: 100px;
    margin-left:10px;
}

.checkout-cart-wrapper{
    padding: 10px !important;
}

.checkout-cart-wrapper .amount-yellow {
    background: #BA983C !important;
}

.checkout-cart-wrapper .amount-red {
    background: #8F2C2C !important;
}
.checkout-cart-wrapper .remove-item{
    font-size: 14px;
    font-weight: 600;
}
.checkout-cart-wrapper .remove-item-btn{
    background-color: white;
    border: none;
}

.checkout-cart-wrapper .status-cell{
    padding: 6px !important;
    width:fit-content;
    color: white;
    font-size: 12px !important;
    border-radius: 8px;
    background-color: #289067;
}

.checkout-cart-wrapper .product-cell{
    text-align: left;
    font-size: 16px !important;
}

.checkout-cart-wrapper .product-cell .qtd{
    text-align: left;
    font-size: 17px !important;
}

.checkout-cart-wrapper .checkout-table th{
    text-align: left;
    font-weight:light;
    border-right: 1px solid #ededed ;
    border-bottom: 1px solid #ededed ;
    height: 40px;
    padding: 10px;
}
.checkout-cart-wrapper .checkout-table td {
    border-right: 0.5px solid #ededed;
    border-bottom: 0.5px solid #ededed;
    height: 100px;
    text-align: right;
    padding: 10px;
}

/* .checkout-cart-wrapper .checkout-table{
    width: 100%;
    min-width: 900px;
}

.checkout-cart-wrapper .checkout-table td{
    height: 1px;
}

.checkout-cart-wrapper .checkout-table .col1{
    width: 10%;
}
.checkout-cart-wrapper .checkout-table .col2{
    width: 20%;
}
.checkout-cart-wrapper .checkout-table .col3, 
.checkout-cart-wrapper .checkout-table .col4, 
.checkout-cart-wrapper .checkout-table .col5, 
.checkout-cart-wrapper .checkout-table .col6{
    width: 15%;
} */

.checkout-page-wrapper .main-section {
    width: 75%;
    margin-right: 20px;
}

.checkout-page-wrapper .user-wrapper {
    padding: 25px 30px;
}

.checkout-page-wrapper .checkbox-holder{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.checkout-page-wrapper .user-wrapper form {
    width: 100%;
}

.checkout-page-wrapper .valid-until{
    margin-right: 40px;
}

.checkout-page-wrapper .cc-provdier-image{
    margin-right: 30px;
    width: 50px;
}

.checkout-page-wrapper .months-parcelas{
    padding: 10px;
}

.checkout-page-wrapper .user-wrapper form input {
    flex: 1 1 auto;
    outline: none;
    padding: 0 15px;
    height: 35px;
    font-size: 14px;
    line-height: 19px !important;
    width: 100%;
}

.checkout-page-wrapper .excel-button{
    max-width: 250px;
}

.checkout-page-wrapper .checkbox{
    margin-right: 5px;
}

.checkout-page-wrapper .save-cc{
    position: relative;
}

.checkout-page-wrapper .save-cc .cover{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 60%;
    z-index: 500;
}

.checkout-page-wrapper .user-wrapper form button {
    flex: 0 0 auto;
    width: 67px !important;
    margin: auto;
    background-color: #DDDDDD;
    color: #333333;
    height: 33px;
    font-size: 14px;
    font-weight: 500;
}

.checkout-page-wrapper .user-wrapper form button,
.checkout-page-wrapper .user-wrapper form input {
    box-sizing: content-box;
    border: 1px solid #DDDDDD;
}

.checkout-page-wrapper .user-wrapper form:focus-within {
    outline: none;
    border: 2px solid #9A9A9A;
}

.checkout-page-wrapper .user-wrapper .limit-width {
    min-width: 270px;
    max-width: 270px;
    width: 33%;
}

.checkout-page-wrapper .user-wrapper .limit-width .info {
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    color: #414141;
    white-space: normal;
}

.checkout-cart-wrapper {
    padding: 30px;
    min-height: 430px !important;
}

.checkout-cart-wrapper .shipping {
    flex: 0 0 auto;
    min-width: 250px;
    max-width: 250px;
}

.checkout-cart-wrapper .listing {
    position: relative;
    flex: 1 1 300px;
    width: 100%;
    min-width: 300px;
    /* margin-right: 20px; */
}

.checkout-purchase-box {
    min-width: 370px;
    max-width: 370px;
    background-color: white;
    padding: 30px 25px;
    height: fit-content !important;
}

.checkout-purchase-box .relative {
    position: relative;
}

.checkout-page-wrapper .payment-selector{
    border-radius: 4px;
    border: 1px solid #C6C6C6;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
}

.checkout-purchase-box .taxa-info-box{
    position: absolute;
    left: 102%;
    z-index: 1000;
    width: 470%;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}

.checkout-purchase-box .loader-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
}

.checkout-purchase-box .action-button {
    width: 100%;
}

.checkout-purchase-box .info-img{
    width: 13px;
    height: 13px;
    margin-left: 5px;
}

.checkout-purchase-box .disclaimer {
    font-size: 12px;
    font-weight: 300;
    color: #303030;
    text-align: left;
}

.checkout-purchase-box .bold-red {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.checkout-purchase-box .success-box img {
    width: 33px;
    height: 33px;
    margin-right: 15px;
}

.checkout-purchase-box .success-box p,
.checkout-purchase-box .success-box a {
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #303030;
}

.checkout-purchase-box .success-box a {
    top: 0 !important;
}

.delivery-date {
    text-transform: capitalize;
}

.success {
    background-color: #1C8870 !important;
}

.checkout-purchase-box .line-24{
    font-size: 14px;
    line-height: 24px;
}

.monthly-section .card-input{
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #A3A3A3;
    margin: 0 10px 20px 0;
    font-size: 18px;
}

.monthly-section .w-full{
    width: 100%;
}

.monthly-section .w-half{
    width: 49%;
}

.monthly-section .w-quarter{
    width: 49%;
}

.monthly-section .placeholder{
    color: #bdbbbb !important;
}

.checkout-page-wrapper .saved-card{
    background-color: white;
    padding: 10px;
    border: 1px solid #C6C6C6;
}

.disabled-green {
    background-color: #2C8F67;
    opacity: 0.5;
}


 .table-container .products-title {
    display: flex;
    padding: 20px 24px 16px 24px;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    width: 988px;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #d8d8d8;
    background: #f6f6f6;
  }
 .table-container .products-title p {
    color: #151e26;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    flex: 1 0 0;
  }

.table .table-head,
.table .table-row,
.table .table-footer {
  display: flex;
  flex-direction: row;
  color: #414141;
  margin: 0px;
}

.table .table-head {
  font-size: 22px;
  font-weight: 700;
}

 .table .first-col {
  width: 32px;
  height: 96px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
  display: flex;
  align-items: center;
  gap: 16px;
}

 .table .second-col {
  width: 346px;
  height: 96px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

 .table .third-col {
  width: 176px;
  height: 96px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
 .table .fourth-col {
  width: 176px;
  height: 96px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

 .table .fifth-col {
  width: 224px;
  height: 96px;
  border-right: 1px hidden #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px hidden #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.table .status-div p {
    color: #151e26;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
  }
  
  .table .status-div-row {
    display: flex;
    width: 90px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
  }
  
  .table .status-div-row p {
    color: #fff;
    text-align: center;
    font-family: SKF Chevin Catalogue;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
  }
  
  .table .deadline {
    justify-content: center;
    display: flex;
    padding-bottom: 0px;
    align-items: flex-end;
    gap: 2px;
  }
  
  .table .deadline p {
    color: #151e26;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
  }

@media only screen and (max-width: 870px) {
    .checkout-page-wrapper {
        padding: 0;
    }
    .checkout-page-wrapper .main-section {
        width: 100% !important;
        margin-right: 0;
    }

    .cart-purchase-box {
        width: 100%;
        min-width: 0 !important;
    }

    .wrap {
        flex-wrap: wrap !important;
    }

    .checkout-page-wrapper .user-wrapper .limit-width {
        min-width: 0;
        width: 100%;
    }

    .checkout-purchase-box {
        min-width: 0;
        max-width: none;
        width: 100%;
    }

    .checkout-purchase-box .line-24{
        line-height: 18px;
    }

    .monthly-section .card-input{
        
    }

    .monthly-section .w-full{
        width: 100%;
    }
    
    .monthly-section .w-half{
        width: 100%;
    }
    
    .monthly-section .w-quarter{
        width: 49%;
    }
    .monthly-section {
        width: 100% !important;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .checkout-cart-wrapper .listing {
        flex: 1 1 auto;
        min-width: 0;
        margin-right: 0;
    }
}