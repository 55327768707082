.checkout-page-wrapper {
    padding: 0 90px;
    margin-bottom: 200px;
}

.checkout-page-wrapper .user-wrapper, .checkout-page-wrapper .checkout-cart-wrapper, .checkout-page-wrapper .checkout-purchase-box {
    border: 1px solid #C6C6C6;
    border-radius: 4px;
}

.checkout-page-wrapper .main-section {
    width: 75%;
    margin-right: 20px;
}

.checkout-page-wrapper .user-wrapper {
    padding: 25px 30px;
}

.checkout-page-wrapper .user-wrapper form {
    width: 100%;
}

.checkout-page-wrapper .valid-until{
    margin-right: 40px;
}

.checkout-page-wrapper .cc-provdier-image{
    margin-right: 30px;
    width: 50px;
}

.checkout-page-wrapper .months-parcelas{
    padding: 10px;
}

.checkout-page-wrapper .user-wrapper form input {
    flex: 1 1 auto;
    outline: none;
    padding: 0 15px;
    height: 35px;
    font-size: 14px;
    line-height: 19px !important;
    width: 100%;
}

.checkout-page-wrapper .checkbox-holder{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.checkout-page-wrapper .excel-button{
    max-width: 250px;
}

.checkout-page-wrapper .checkbox{
    margin-right: 5px;
}

.checkout-page-wrapper .save-cc{
    position: relative;
}

.checkout-page-wrapper .save-cc .cover{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 60%;
    z-index: 500;
}

.checkout-page-wrapper .user-wrapper form button {
    flex: 0 0 auto;
    width: 67px !important;
    margin: auto;
    background-color: #DDDDDD;
    color: #333333;
    height: 33px;
    font-size: 14px;
    font-weight: 500;
}

.checkout-page-wrapper .user-wrapper form button,
.checkout-page-wrapper .user-wrapper form input {
    box-sizing: content-box;
    border: 1px solid #DDDDDD;
}

.checkout-page-wrapper .user-wrapper form:focus-within {
    outline: none;
    border: 2px solid #9A9A9A;
}

.checkout-page-wrapper .user-wrapper .limit-width {
    min-width: 270px;
    max-width: 270px;
    width: 33%;
}

.checkout-page-wrapper .user-wrapper .limit-width .info {
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    color: #414141;
    white-space: normal;
}

.checkout-cart-wrapper {
    padding: 30px;
}

.checkout-cart-wrapper .shipping {
    flex: 0 0 auto;
    min-width: 250px;
    max-width: 250px;
}

.checkout-cart-wrapper .listing {
    position: relative;
    flex: 1 1 400px;
    width: 100%;
    min-width: 400px;
    margin-right: 50px;
}

.checkout-purchase-box {
    min-width: 370px;
    max-width: 370px;
    background-color: white;
    padding: 30px 25px;
    height: fit-content !important;
}

.checkout-purchase-box .relative {
    position: relative;
}

.checkout-page-wrapper .payment-selector{
    border-radius: 4px;
    border: 1px solid #C6C6C6;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
}

.checkout-purchase-box .taxa-info-box{
    position: absolute;
    left: 102%;
    z-index: 1000;
    width: 470%;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}

.checkout-purchase-box .loader-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
}

.checkout-purchase-box .action-button {
    width: 100%;
}

.checkout-purchase-box .info-img{
    width: 13px;
    height: 13px;
    margin-left: 5px;
}

.checkout-purchase-box .disclaimer {
    font-size: 12px;
    font-weight: 300;
    color: #303030;
    text-align: left;
}

.checkout-purchase-box .bold-red {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.checkout-purchase-box .success-box img {
    width: 33px;
    height: 33px;
    margin-right: 15px;
}

.checkout-purchase-box .success-box p,
.checkout-purchase-box .success-box a {
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #303030;
}

.checkout-purchase-box .success-box a {
    top: 0 !important;
}

.delivery-date {
    text-transform: capitalize;
}

.success {
    background-color: #1C8870 !important;
}

.checkout-purchase-box .line-24{
    font-size: 14px;
    line-height: 24px;
}

.monthly-section .card-input{
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #A3A3A3;
    margin: 0 10px 20px 0;
    font-size: 18px;
}

.monthly-section .w-full{
    width: 100%;
}

.monthly-section .w-half{
    width: 49%;
}

.monthly-section .w-quarter{
    width: 49%;
}

.monthly-section .placeholder{
    color: #bdbbbb !important;
}

.checkout-page-wrapper .saved-card{
    background-color: white;
    padding: 10px;
    border: 1px solid #C6C6C6;
}

.disabled-green {
    background-color: #2C8F67;
    opacity: 0.5;
}

@media only screen and (max-width: 870px) {
    .checkout-page-wrapper {
        padding: 0;
    }
    .checkout-page-wrapper .main-section {
        width: 100% !important;
        margin-right: 0;
    }

    .cart-purchase-box {
        width: 100%;
        min-width: 0 !important;
    }

    .wrap {
        flex-wrap: wrap !important;
    }

    .checkout-page-wrapper .user-wrapper .limit-width {
        min-width: 0;
        width: 100%;
    }

    .checkout-purchase-box {
        min-width: 0;
        max-width: none;
        width: 100%;
    }

    .checkout-purchase-box .line-24{
        line-height: 18px;
    }

    .monthly-section .card-input{
        
    }

    .monthly-section .w-full{
        width: 100%;
    }
    
    .monthly-section .w-half{
        width: 100%;
    }
    
    .monthly-section .w-quarter{
        width: 49%;
    }
    .monthly-section {
        width: 100% !important;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .checkout-cart-wrapper .listing {
        flex: 1 1 auto;
        min-width: 0;
        margin-right: 0;
    }
}