.order-listing .header {
    border-top: 1px solid #C6C6C6;
    border-left: 1px solid #C6C6C6;
    border-right: 1px solid #C6C6C6;
    border-radius: 4px 4px 0 0;
    background-color: #F6F6F6;
    padding: 15px 30px;
}

.order-listing .content {
    border: 1px solid #C6C6C6;
    border-radius: 0 0 4px 4px;
    padding: 15px 30px;
    align-items: center;
}

.order-listing .header .first-box div {
    margin-right: 20px;
}

.order-listing .header p {
    font-size: 16px;
    font-weight: 700;
    color: #151515;
    margin-bottom: 5px;
    margin-right: 5px;
}

.order-listing .header .title {
    font-size: 16px;
    font-weight: 600 !important;
    color: #151515;
    margin-bottom: 5px;
    margin-right: 5px;
    flex: 0 0 auto;
}

.order-listing .content .section2 .button1, .order-listing .content .section2 .button2 {
    display: block;
    width: 190px;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 5px;
}

.order-listing .content .section1 {
    width: 75%;
}

.order-listing .content .section1 img {
    max-width: 100px;
    max-height: 150px;
    margin-right: 20px;
    padding: 6px;
}

.order-listing .content .section1 p {
    font-weight: 700;
    font-size: 14px;
    color: #414141;
    margin-bottom: 5px;
}

.order-listing .content .section1 span {
    font-weight: 600;
    font-size: 14px;
    color: #414141;
    top: 0px !important;
}

.order-listing .content .section1 div {
    max-width: 70%;
}

.order-listing .content .section1 .buy-again {
    background-color: #142F4D;
    border: 0;
    border-radius: 4px;
    color: #FFFFFF;
    height: 32px;
    width: 155px;
    margin: 5px 0;
}

.order-listing .content .section1 .product-name {
    white-space: nowrap;
    font-size: 16px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-listing .content .section2 .button1 {
    background-color: #142F4D;
    color: #FFFFFF;
    border: 0;
}

.order-listing .content .section2 .button2 {
    background-color: #F6F6F6;
    color: #262626;
    border: 1px solid #DDDDDD;
}

@media only screen and (max-width: 885px) {
    .order-listing .content .section1 {
        width: 100%;
    }
    .order-listing .content .section1 img {
        max-width: 150px;
        width: auto;
        flex: 1 1 auto;
        min-width: 0;
    }
    .order-listing .content .section1 .product-name {
        white-space: normal;
        font-size: 16px;
        min-width: 265px;
    }

    .order-listing .content .section2{
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }

}


@media only screen and (max-width: 555px) {
    .order-listing .content .section2{
        width: 100%;
        margin-top: 20px;
        display: block;
    }

    .order-listing .content .section2 button{
        width: 100% !important;
    }

    .order-listing .content .section1 {
        display: block;
        text-align: center;
    }

    .order-listing .content .section1 div{
        width: 100%;
        max-width: none;
        text-align: center;
    }

    .order-listing .content .section1 .product-name {
        min-width: 0;
    }
}