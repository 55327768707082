.minimenu-wrapper{
    background-color: white;
    border-radius: 4px;
    width: 300px;
    height: 330px;
    margin-right: 20px;
    z-index: 1;
}

.minimenu-max{
    max-width: 494px;
    margin: auto;
}

.minimenu-item{
    background-color: #232F3E;
    border-radius: 2px;
    padding: 20px 10px;
    margin: 9px;
    width: 42%;
    max-width: 105px;
    height: 96px;
    color: white;
    text-align: center;
}

.minimenu-group{
    width: 50%;
    min-width: 230px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: space-between;
}


@media only screen and (max-width: 700px) {

    .minimenu-wrapper{
        display: block !important;
        margin: auto;
        min-width: 300px;
        width: 100%;
        height: auto;
        margin-right: 0px;
        margin-bottom: 20px;
    }

}